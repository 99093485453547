export const initialStateTransactions = {
    transaction: [],
    transactionsCopy: [],
    loading: false,
    errorMessage: null,
    states: [],
    countries: [],
};

export const TransactionsReducer = (state = initialStateTransactions, action) => {
    switch (action.type) {
        case 'REQUEST_TRANSACTIONS':
            return {
                ...state,
                loading: true,
                errorMessage: null,
            };
        case 'GET_TRANSACTIONS_SUCCESS':
            return {
                ...state,
                transactions: action.payload,
                transactionsCopy: action.payload,
                loading: false,
            };
        case 'GET_TRANSACTIONS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'REQUEST_ADD_TRANSACTION':
            return {
                ...state,
                loading: true,
            };
        case 'ADD_TRANSACTION_SUCCESS':
            return {
                ...state,
                transaction: action.payload,
            };
        case 'GET_STATE_SUCCESS':
            return {
                ...state,
                states: action.payload,
            };
        case 'GET_COUNTRIES_SUCCESS':
            return {
                ...state,
                countries: action.payload,
            };
        case 'HANDLE_ERROR':
            return {
                ...state,
                errorMessage: action.error,
            };
        case 'REQUEST_DELETE_TRANSACTION':
            return {
                ...state,
                loading: true,
            };
        case 'DELETE_TRANSACTION_SUCCESS':
            return {
                ...state,
                loading: false,
                transactionDetails: null,
            };
        case 'DELETE_TRANSACTION_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
