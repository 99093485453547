import React from 'react';
import { Col } from 'react-bootstrap';
import './style.scss';

const MockupListingLayout = ({ title, titleIconClass, buttons = '', children, textColor }) => {
    return (
        <div className="w-100 content-section">
            <div className="px-3">
                <Col
                    style={{ justifyContent: 'space-between' }}
                    className="w-100 mt-3 mb-3 d-flex flex-column flex-sm-row"
                >
                    <h3 style={{ color: `${textColor}` }}>
                        <i className={`${titleIconClass}`} style={{ color: `${textColor}` }} /> {title}
                    </h3>
                    <h3 className="header">{buttons}</h3>
                </Col>
            </div>
            <div className="px-3">{children}</div>
        </div>
    );
};

export default MockupListingLayout;
