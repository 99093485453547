import { listAddonAssignments } from '../../services/addonAssignmentService';

export async function getAddonAssignments(dispatch, payload) {
    try {
        // dispatch({ type: 'REQUEST_BOOKING_DETAILS' });
        let response = await listAddonAssignments({
            bookingId: payload.bookingId,
            vehicleAssignmentId: payload.vehicleAssignmentId,
            isStoreProc: payload.isStoreProc,
        });

        if (response.data) {
            dispatch({ type: 'ADDON_ASSIGNMENTS_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'ADDON_ASSIGNMENTS_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'ADDON_ASSIGNMENTS_ERROR', error: error });
    }
}
