import Service from './baseService';
import { getCompanyId } from '../config';

export const getBookingVehicleAssignmentsService = (bookingId) => {
    return Service.get(`/customer/bookings/vehicle-assignments`, {
        params: { companyId: getCompanyId(), bookingId: bookingId },
    });
};

export const getBookingVehicleAssignmentDetailsService = (bookingId, id) => {
    return Service.get(`/customer/bookings/vehicle-assignments/${id}`, {
        params: { companyId: getCompanyId(), bookingId: bookingId },
    });
};

export const listVehicleSegments = (relatedVehicleAssignment) => {
    let params = {};
    params.account = getCompanyId();
    params.relatedVehicleAssignment = relatedVehicleAssignment;
    return Service.get(`/customer/bookings/vehicleSegment`, { params });
};

export const listAddonAssignments = (bookingId, vehicleAssignmentId) => {
    let params = { companyId: getCompanyId() };
    params.vehicleAssignmentId = vehicleAssignmentId;
    params.bookingId = bookingId;

    return Service.get(`/customer/bookings/addOnAssignment`, { params });
};

export const listDispatch = (bookingId, vehicleAssignmentId) => {
    let params = { companyId: getCompanyId() };
    params.vehicleAssignmentID = vehicleAssignmentId;
    params.bookingId = bookingId;
    return Service.get(`/customer/bookings/dispatch`, { params });
};

export const getDispatchFilesService = (vehicleAssignmentID) => {
    let params = { companyId: getCompanyId() };
    params.vehicleAssignmentID = vehicleAssignmentID;
    return Service.get(`/customer/bookings/documents`, { params });
};
