import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MockupLayout from '../../../../Components/MockupLayout';
import CustomAccordion from '../../../../Components/CustomAccordion';
import SimpleTable from '../../../../Components/SimpleTable';
import Loading from '../../../../Components/Loading';
import { formatCurrency, totalAmountValues } from '../../../../utils/helper';
import { getQuotes, editQuote, useQuotesState, getTripSettings, useAccountState } from '../../../../Context';
import { Alert, Col, Row, Form, Button } from 'react-bootstrap';
import { getCustomerDetails } from '../../../../config';
import HelperFunction from '../../../../Components/HelperFunction';

const MockupQuotes = ({ history }) => {
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const [upcomingFilter, setUpcomingFilter] = useState(30);
    const [pastFilter, setPastFilter] = useState(30);
    const [upcomingSearchFilter, setUpcomingSearchFilter] = useState('');
    const [pastSearchFilter, setPastSearchFilter] = useState('');
    const [showAllVariations, setShowAllVariations] = useState(0);
    const {
        quotesDispatch,
        quotes: { priorQuotes, upcomingQuotes, errorMessage },
    } = useQuotesState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [loading, setLoading] = useState(false);
    const user = getCustomerDetails();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getQuotes(quotesDispatch, showAllVariations, upcomingFilter, pastFilter);
            setLoading(false);
        })();
    }, [quotesDispatch, upcomingFilter, pastFilter, AccountsDispatch, showAllVariations]);

    const handleRequestQuote = async (e, item) => {
        e.stopPropagation();
        try {
            if (item && item.id) {
                await editQuote(quotesDispatch, item.id, { customerStatusID: 2 }, { customerStatusID: 2 }, true);
                await getQuotes(quotesDispatch, showAllVariations, upcomingFilter, pastFilter);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRejectQuote = async (e, item) => {
        e.stopPropagation();
        try {
            if (item && item.id) {
                await editQuote(quotesDispatch, item.id, { customerStatusID: 1 }, { customerStatusID: 1 });
                await getQuotes(quotesDispatch, showAllVariations, upcomingFilter, pastFilter);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };
    const renderTotal = (item) => {
        if (item.options > 0) {
            return 'Multiple Options';
        }
        return `$${formatCurrency(Number(item['total']))}`;
    };

    const onAllVariationsChange = (e) => {
        setShowAllVariations(Boolean(!showAllVariations));
    };

    const renderQuoteLink = (item) => {
        return item.quoteID ? (
            <Link className="d-block p-1 mb-0" to={`/quotes/${item.id}`}>
                <u style={{ color: 'blue' }}>{item.quoteID}</u>
            </Link>
        ) : (
            ''
        );
    };

    const renderButton = (item) => {
        if (item.options > 1 && item.quoteStatusID !== 4 && ![1, 2].includes(item.customerStatusID)) {
            return (
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.widgetUrl, '_blank');
                    }}
                    style={{ backgroundColor: 'Blue', border: 'none' }}
                >
                    Review & Accept
                </Button>
            );
        } else if (item.quoteStatusID === 4) {
            return (
                <p>
                    <i>Booked Quote</i>
                </p>
            );
        } else if (item.customerStatusID === 1) {
            return (
                <p>
                    <i>Quote Rejected</i>
                </p>
            );
        } else if (item.customerStatusID === 2) {
            return (
                <p>
                    <i>Quote Has Been Requested</i>
                </p>
            );
        } else {
            return (
                <>
                    <Button
                        style={{ backgroundColor: '#367609', border: 'none' }}
                        onClick={(e) => handleRequestQuote(e, item)}
                    >
                        Request This Quote
                    </Button>
                    <Button
                        className="ml-2"
                        style={{ backgroundColor: '#F8551F', border: 'none' }}
                        onClick={(e) => handleRejectQuote(e, item)}
                    >
                        Reject This Quote
                    </Button>
                </>
            );
        }
    };

    const onRowClick = (item) => {
        history.push(`/quotes/${item.id}`);
    };
    const handleUpcomingSearch = async (e) => {
        setUpcomingSearchFilter(e.target.value);
        if (e.target.value !== '' || typeof e.target.value !== 'undefined') {
            setLoading(true);
            quotesDispatch({ type: 'FILTER_UPCOMING_BY_SEARCH_TEXT', payload: { searchText: e.target.value } });
            setLoading(false);
        }
    };
    const handlePastSearch = async (e) => {
        setPastSearchFilter(e.target.value);
        if (e.target.value !== '' || typeof e.target.value !== 'undefined') {
            setLoading(true);
            quotesDispatch({ type: 'FILTER_PAST_BY_SEARCH_TEXT', payload: { searchText: e.target.value } });
            setLoading(false);
        }
    };

    const rowStyle = (item) => {
        if (item.customerStatusID === 2) return { backgroundColor: '#FFFFE0' };
        else if (item.quoteStatusID === 4) return { backgroundColor: '#90EE90' };
        else return { backgroundColor: '#FFF' };
    };

    return tripSettings ? (
        <MockupLayout title={'Quotes'} titleIconClass={'fas fa-file-alt'} textColor={tripSettings?.textColor}>
            {errorMessage && (
                <Alert variant="danger">Some error occurred while retrieving quotes. Please try again later.</Alert>
            )}
            <Row>
                <Col>
                    <Form.Check
                        className="fw-normal mx-2 my-1"
                        type="checkbox"
                        id="past"
                        name="past"
                        label="Show All Variations"
                        onChange={onAllVariationsChange}
                    />
                </Col>
            </Row>
            <CustomAccordion
                id={1}
                title={'Upcoming Quotes'}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                <div className="d-flex justify-content-between mb-2">
                    <Form.Control
                        as="select"
                        value={upcomingFilter}
                        className="w-25"
                        onChange={(e) => setUpcomingFilter(e.target.value)}
                    >
                        <option value={0}>Today</option>
                        <option value={1}>Tomorrow</option>
                        <option value={7}>Next 7 Days</option>
                        <option value={30}>Next 30 Days</option>
                        <option value={60}>Next 60 Days</option>
                        <option value={180}>Next 180 Days</option>
                        <option value={500}>All Time</option>
                    </Form.Control>
                    <div className="d-flex mr-5">
                        <HelperFunction message="This field will only search records the correspond to the date filter currently selected." />

                        <input
                            className="form-control"
                            value={upcomingSearchFilter}
                            id="upcoming_quotes_search"
                            onChange={handleUpcomingSearch}
                            type="search"
                            placeholder="Search Quotes..."
                        />
                    </div>
                </div>
                <SimpleTable
                    data={upcomingQuotes}
                    showFooter={true}
                    bordered={true}
                    loading={loading}
                    mainColor={tripSettings?.mainColor}
                    rowStyle={rowStyle}
                    onClick={onRowClick}
                    fieldNames={[
                        user.showParentCompany ? ['parentCompany', 'Parent Company'] : [],
                        ['company', 'Company'],
                        ['quoteID', 'Quote ID', (item) => renderQuoteLink(item), 'TOT'],
                        ['tripReference', 'Trip Reference'],
                        ['firstPickupLocation', 'First Pick Up Location'],
                        ['initialDeparture', 'Initial Departure'],
                        ['finalArrival', 'Final Arrival'],
                        ['total', 'Quotation Total', renderTotal, totalAmountValues(upcomingQuotes, 'total')],
                        ['', '', renderButton],
                    ]}
                />
            </CustomAccordion>

            <CustomAccordion
                id={2}
                title={'Past Quotes'}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                <div className="d-flex justify-content-between mb-2">
                    <Form.Control
                        as="select"
                        value={pastFilter}
                        className="w-25"
                        onChange={(e) => setPastFilter(e.target.value)}
                    >
                        <option value={0}>Today</option>
                        <option value={1}>Yesterday</option>
                        <option value={7}>Last 7 Days</option>
                        <option value={30}>Last 30 Days</option>
                        <option value={60}>Last 60 Days</option>
                        <option value={180}>Last 180 Days</option>
                        <option value={500}>All Time</option>
                    </Form.Control>
                    <div className="d-flex mr-5">
                        <HelperFunction message="This field will only search records the correspond to the date filter currently selected." />

                        <input
                            className="form-control"
                            value={pastSearchFilter}
                            id="past_quotes_search"
                            onChange={handlePastSearch}
                            type="search"
                            placeholder="Search Quotes..."
                        />
                    </div>
                </div>
                <SimpleTable
                    data={priorQuotes}
                    showFooter={true}
                    bordered={true}
                    loading={loading}
                    rowStyle={rowStyle}
                    mainColor={tripSettings?.mainColor}
                    onClick={onRowClick}
                    fieldNames={[
                        user.showParentCompany ? ['parentCompany', 'Parent Company'] : [],
                        ['company', 'Company'],
                        ['quoteID', 'Quote ID', (item) => renderQuoteLink(item), 'TOT'],
                        ['tripReference', 'Trip Reference'],
                        ['firstPickupLocation', 'First Pick Up Location'],
                        ['initialDeparture', 'Initial Departure'],
                        ['finalArrival', 'Final Arrival'],
                        ['total', 'Quotation Total', renderTotal, totalAmountValues(priorQuotes, 'total')],
                        ['', '', renderButton],
                    ]}
                />
            </CustomAccordion>
        </MockupLayout>
    ) : (
        <Loading loading={loading} />
    );
};

export default MockupQuotes;
