import { getLogoService, getThemeConfigService } from '../../services/themeConfigService';
import logo from '../../assets/TBN-Drives-Logo.png';
import footerLogo from '../../assets/TBN-Drives-white.png';

export async function setMockupTheme(dispatch) {
    let mockupTheme = {
        backgroundColor: 'gray',
        fontColor: 'white',
        logo: logo,
        footerLogo: footerLogo,
    };
    try {
        dispatch({ type: 'REQUEST_FETCH_MOCKUP_THEME' });
        let response = await getThemeConfigService();
        if (response.data) {
            let config = response.data;
            mockupTheme = {
                backgroundColor: config.backgroundColor,
                fontColor: config.fontColor,
                primaryTextColor: config.primaryTextColor,
                logo: config.logo || logo,
                footerLogo: config.footerLogo,
            };
        }
        dispatch({ type: 'SET_MOCKUP_THEME', payload: mockupTheme });
    } catch (error) {
        dispatch({ type: 'SET_MOCKUP_THEME', payload: mockupTheme });
    }
}

export async function getLogo(dispatch) {
    const mockupTheme = {
        logo: logo,
    };
    try {
        dispatch({ type: 'REQUEST_FETCH_MOCKUP_THEME' });
        let response = await getLogoService();
        if (response.data) {
            let config = response.data;
            mockupTheme.logo = config.logo;
            return config.logo;
        }
        dispatch({ type: 'SET_MOCKUP_LOGO', payload: mockupTheme });
        return '';
    } catch (error) {
        dispatch({ type: 'SET_MOCKUP_LOGO', payload: mockupTheme });
        return '';
    }
}
