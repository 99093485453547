import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Spinner, Image, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuthState } from '../../Context';
import { loginUser, getLoginImage } from '../../Context/actions/authActions';
import './Login.scss';

const LoginContainer = (props) => {
    const schema = yup.object().shape({
        email: yup.string().email('Please enter a valid email').required('Email is required'),
        password: yup.string().required('Password is required'),
    });

    const { register, handleSubmit } = useForm(schema);
    const [loading, setLoadingState] = useState(false);
    const {
        authDispatch,
        authUser: { errorMessage, loginImage },
    } = useAuthState();

    useEffect(() => {
        (async () => {
            try {
                setLoadingState(true);
                await getLoginImage(authDispatch);
                setLoadingState(false);
            } catch (e) {
                console.log('e', e);
                setLoadingState(false);
            }
        })();
    }, [authDispatch]);

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const { email = '', password = '' } = data;
            setLoadingState(true);
            try {
                let response = await loginUser(authDispatch, { email, password });
                if (response && response.user) {
                    sessionStorage.setItem('lastTimeStamp', new Date());
                    props.history.push('/bookings');
                }
            } catch (error) {
                console.log(error);
            }
            setLoadingState(false);
        })();
    };

    return (
        <>
            <div className="login-page-wrapper">
                <div className="d-flex login-box align-items-center">
                    <div className="images-wrap">
                        <Image src={loginImage.customerPortalLoginImageURL} />
                    </div>
                    <div className="login-box-content">
                        <div className="box-content w-100">
                            <div className="box-input d-flex justify-content-center align-items-center  ">
                                <h3>{loginImage.customerPortalMainGreeting}</h3>
                                <h6>{loginImage.customerPortalSubGreeting}</h6>

                                <Row className="w-100 form-box  ">
                                    <Col>
                                        <div>
                                            <div className="form-input">
                                                <Form.Group>
                                                    <Col>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter username"
                                                            name="email"
                                                            required
                                                            autoComplete="off"
                                                            isInvalid={errorMessage}
                                                            {...register('email')}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </div>

                                            <div className="form-input">
                                                <Form.Group>
                                                    <Col>
                                                        <Form.Control
                                                            required
                                                            name="password"
                                                            placeholder="Password"
                                                            type="password"
                                                            {...register('password')}
                                                            isInvalid={errorMessage}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                {errorMessage ? (
                                                    <Col>
                                                        <p
                                                            style={{
                                                                color: 'red',
                                                                fontSize: '12px',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {errorMessage}
                                                        </p>
                                                    </Col>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <u className="w-100 forget-link">
                                    <Link id="forgotPassword" to={'/auth/forgot-password'}>
                                        Forgot Password?
                                    </Link>
                                </u>
                                <div className="w-100 ">
                                    <div className="sign-up-button ">
                                        <Button id="login" onClick={onSubmit} variant="info">
                                            Sign In
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && (
                <>
                    <Modal transition="false" show={true} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </>
            )}
        </>
    );
};

export default LoginContainer;
