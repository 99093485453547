import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { DatePicker } from 'react-tempusdominus-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import './style.scss';
import {
    getInvoiceDetails,
    getInvoices,
    getTransactions,
    useInvoicesState,
    useTransactionsState,
    usePaymentTermsState,
    getPaymentTerms,
    useAccountState,
    getTripSettings,
} from '../../../../Context';
import { dispatchModal, formatCurrency, convertQueryStringToObject, renderDateTime } from '../../../../utils/helper';
import MockupLayout from '../../../../Components/MockupLayout';
import Loading from '../../../../Components/Loading';
import { printInvoiceService } from '../../../../services/invoicesService';
import CustomAccordion from '../../../../Components/CustomAccordion';
import SimpleTable from '../../../../Components/SimpleTable';
import { getInvoiceTrasactions } from '../../../../Context/actions/invoiceActions';

const InvoiceDetails = (props) => {
    const history = useHistory();
    const goToPreviousPath = useCallback(() => {
        history.goBack();
    }, [history]);
    const invoiceId = props.match.params.id;
    const [loading, setLoading] = useState(false);
    const [editState, setEditState] = useState(false);
    const [firstLoaded, setFirstLoaded] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [transectionTotal, setTransectionTotal] = useState(0);
    const { reset, control, setValue, watch } = useForm({});
    const {
        invoiceDispatch,
        invoice: { errorMessage, transactions },
    } = useInvoicesState();
    const {
        paymentTermsDispatch,
        paymentTerms: { paymentTerms },
    } = usePaymentTermsState();
    const { transactionsDispatch } = useTransactionsState();
    const [dueDate, setDueDate] = useState(null);
    const watcher = watch(['paymentTermID', 'date']);
    const [invoiceStatus, setInvoiceStatus] = useState({
        color: '',
        text: '',
    });

    useEffect(() => {
        let paymentTermID = Number(watcher.paymentTermID);
        let format = 'MM-DD-YYYY';
        let dueDate;
        let invoiceDueDate = watcher.date ? moment(watcher.date) : moment(invoiceDetails.date);
        if (invoiceDetails.invoiceDueDate && !editState) {
            setDueDate(invoiceDetails.invoiceDueDate);
            return;
        }
        if (paymentTermID) {
            if (paymentTermID === 8 || paymentTermID === 1) dueDate = invoiceDueDate.format(format);
            else if (paymentTermID === 2) dueDate = invoiceDueDate.add(7, 'days').format(format);
            else if (paymentTermID === 3) dueDate = invoiceDueDate.add(10, 'days').format(format);
            else if (paymentTermID === 9) dueDate = invoiceDueDate.add(180, 'days').format(format);
            else if (paymentTermID === 4) dueDate = invoiceDueDate.add(30, 'days').format(format);
            else if (paymentTermID === 5) dueDate = invoiceDueDate.add(45, 'days').format(format);
            else if (paymentTermID === 6) dueDate = invoiceDueDate.add(60, 'days').format(format);
            else if (paymentTermID === 7) dueDate = invoiceDueDate.add(90, 'days').format(format);
            else dueDate = null;

            if (dueDate && editState) setDueDate(dueDate);
            else {
                setDueDate(null);
            }
        }
    }, [editState, invoiceDetails.date, invoiceDetails.invoiceDueDate, watcher]);

    useEffect(() => {
        let invoiceStatusObj = {
            color: '',
            text: '',
        };
        if (invoiceDetails.paidInFull && Number(invoiceDetails.paidInFull) === 1) {
            invoiceStatusObj = {
                color: 'text-success',
                text: 'Paid in Full',
            };
        } else if (
            !invoiceDetails.paidInFull &&
            invoiceDetails.invoiceDueDate &&
            moment().startOf('day').isSameOrBefore(moment(invoiceDetails.invoiceDueDate))
        ) {
            invoiceStatusObj = {
                color: '',
                text: `Due ${renderDateTime(invoiceDetails, 'invoiceDueDate', null, true)}`,
            };
        } else {
            invoiceStatusObj = {
                color: 'text-danger',
                text: `Past Due`,
            };
        }

        if (invoiceDetails.voidedAt) {
            invoiceStatusObj = {
                color: 'text-danger',
                text: `Voided`,
            };
        }

        setInvoiceStatus(invoiceStatusObj);

        return () => {
            setInvoiceStatus({
                color: '',
                text: '',
            });
        };
    }, [invoiceDetails]);

    useEffect(() => {
        (async () => {
            invoiceDispatch({ type: 'RESET_INVOICE' });
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getPaymentTerms(paymentTermsDispatch);

            if (invoiceId) {
                const details = await getInvoiceDetails(invoiceDispatch, invoiceId);
                setInvoiceDetails(details);

                if (!details || Object.keys(details).length === 0) {
                    dispatchModal(
                        'Oops, the requested record does not appear to exist for this account',
                        null,
                        goToPreviousPath
                    );
                    setLoading(false);
                }
                // Set form data
                if (details && details.id) {
                    let formData = {
                        ...details,
                    };
                    delete formData.bookings;
                    if (details.invoiceDueDate) setDueDate(details.invoiceDueDate);
                    reset({
                        ...details,
                    });
                }
                setFirstLoaded(true);
                setLoading(false);
            } else {
                const allInvoices = await getInvoices(invoiceDispatch);
                reset({
                    invoiceNumber: allInvoices.length,
                });
                setValue('date', moment());
                setLoading(false);
                setEditState(true);
            }
        })();
    }, [invoiceId, reset, invoiceDispatch, goToPreviousPath, paymentTermsDispatch, setValue]);

    useEffect(() => {
        (async () => {
            if (firstLoaded && invoiceId) {
                // Fetch & Set transactions
                setLoading(true);
                const allTransactions = await getTransactions(transactionsDispatch, { invoiceId });
                let filteredTransaction =
                    allTransactions &&
                    allTransactions.filter(
                        (t) =>
                            Number(t.transactionTypeID) !== 8 &&
                            Number(t.transactionTypeID) !== 9 &&
                            Number(t.transactionTypeID) !== 11
                    );

                const tempTransactionTotal = filteredTransaction.reduce((total, cur) => {
                    if (cur.amount && !isNaN(cur.amount) && !cur.cancelledAt) total += Number(cur.amount);
                    return total;
                }, 0);

                // let invoiceAmount = getInvoiceAmount(allTransactions)
                // let amountOutstanding = getAmountOutstanding(allTransactions);
                let invoicedAmount = invoiceDetails.invoicedAmount;
                let amountPaid = invoiceDetails.paidAmount;
                let amountOutstanding = invoicedAmount - tempTransactionTotal;
                setValue('invoiceTotal', formatCurrency(invoicedAmount));
                setValue('amountPaid', formatCurrency(amountPaid));
                setValue('amountOutstanding', formatCurrency(amountOutstanding));
                setTransectionTotal(tempTransactionTotal);
                await getInvoiceTrasactions(invoiceDispatch, invoiceId);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstLoaded, invoiceId, transactionsDispatch, setValue]);

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    const payInvoiceHandler = () => {
        window.open(
            `${window.location.origin}/p/pay-invoice?ts=${moment(
                invoiceDetails.createdAt
            ).unix()}&invoiceId=${invoiceId}`,
            '_blank'
        );
    };

    const handlePrint = async () => {
        if (invoiceDetails.voidedAt) {
            window.open(invoiceDetails.fileUrl, '_blank');
        } else {
            try {
                setLoading(true);
                const pdfData = await printInvoiceService(invoiceId);
                setLoading(false);
                let blob = new Blob([pdfData.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                if (props.isMobile) {
                    window.open(link.href);
                } else {
                    link.download = `Invoice ${invoiceDetails.invoiceNumber}.pdf`;
                    link.click();
                }
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
    };

    let errorContent = '';
    if (errorMessage) {
        errorContent = (
            <Col xl={12}>
                <Alert variant="danger">Invoice detail not found!</Alert>
            </Col>
        );
    }

    const rowStyle = (item) => {
        return { backgroundColor: item.cancelledAt ? '#f08080' : '#FFFFFF' };
    };

    return (
        <MockupLayout
            titleIconClass="fas fa-file-invoice"
            title="Invoice"
            textColor={tripSettings?.textColor}
            buttons={
                <>
                    <Button
                        onClick={() => {
                            props.history.push(`/invoices`);
                        }}
                        style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                    >
                        BACK TO INVOICE
                    </Button>
                </>
            }
        >
            <CustomAccordion
                id={1}
                title="Invoice Details"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <Row className={`mx-1`}>
                    {errorContent}
                    {/*Invoice Number*/}
                    {invoiceId && (
                        <Col xs={12} md={6} lg={4}>
                            <FormGroup>
                                <FormGroup>
                                    <Form.Label>Invoice Number</Form.Label>
                                    <Form.Control
                                        id="invoiceNumber"
                                        name="invoiceNumber"
                                        placeholder="Invoice Number"
                                        value={invoiceDetails.invoiceNumber}
                                        readOnly
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    )}

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Form.Label>Invoice Status</Form.Label>
                            <Form.Control
                                type="text"
                                id="invoiceStatus"
                                name="invoiceStatus"
                                className={invoiceStatus?.color}
                                value={invoiceId ? invoiceStatus?.text : ''}
                                readOnly
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={`mx-1`}>
                    {/*Date of Invoice*/}
                    <Col xs={12} md={6} lg={3} className={'departure'}>
                        <FormGroup>
                            <Form.Label>Date Created</Form.Label>
                            <Controller
                                render={(props) => (
                                    <label className="test">
                                        <DatePicker
                                            date={invoiceDetails ? invoiceDetails.date : undefined}
                                            defaultDate={props.value}
                                            onChange={(date) => {
                                                if (date.date) {
                                                    props.onChange(date);
                                                    setValue('date', date.date);
                                                }
                                            }}
                                            className={!editState ? 'customBack' : ''}
                                            readOnly={!editState}
                                        />
                                    </label>
                                )}
                                name="date"
                                control={control}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={2}>
                        {invoiceDetails.bookingID && (
                            <FormGroup>
                                <Form.Label>Booking ID</Form.Label>
                                <Link className="d-block p-1 mb-0" to={`/bookings/${invoiceDetails.bookingID}`}>
                                    <span className="font-weight-bold">
                                        {invoiceDetails.bookingFormattedID}
                                        <i className="fa fa-link ml-2" />
                                    </span>
                                </Link>
                            </FormGroup>
                        )}
                    </Col>
                    {/*Invoice due date*/}
                    <Col xs={12} md={6} lg={3} className={'departure'}>
                        <FormGroup>
                            <Form.Label>Invoice Due Date</Form.Label>
                            <Controller
                                render={(props) => (
                                    <label className="test">
                                        <DatePicker date={dueDate ? dueDate : null} readOnly={true} />
                                    </label>
                                )}
                                name="invoiceDueDate"
                                control={control}
                            />
                        </FormGroup>
                    </Col>
                    {/*Invoice terms*/}
                    <Col xs={12} md={6} lg={3}>
                        <FormGroup>
                            <Form.Label>Invoice Terms</Form.Label>
                            <Form.Control
                                as="select"
                                id="paymentTermID"
                                name="paymentTermID"
                                value={invoiceDetails.paymentTermID}
                                readOnly
                                disabled
                            >
                                <option value="">No Selection</option>
                                {paymentTerms &&
                                    paymentTerms.map((r) => (
                                        <option key={r.id} value={r.id}>
                                            {r.description}
                                        </option>
                                    ))}
                            </Form.Control>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={`mx-1`}>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Form.Label>Invoice Total</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    id="invoicedAmount"
                                    name="invoicedAmount"
                                    value={invoiceDetails.invoicedAmount}
                                    placeholder="Invoice Total"
                                    readOnly
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Form.Label>Amount Paid</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    id="paidAmount"
                                    name="paidAmount"
                                    value={invoiceDetails.paidAmount}
                                    placeholder="Amount Paid"
                                    readOnly
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    {/*amountOutstanding*/}
                    <Col xs={12} md={6} lg={3}>
                        <FormGroup>
                            <Form.Label>Amount Outstanding</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    id="amountOutstanding"
                                    name="amountOutstanding"
                                    value={parseFloat(
                                        invoiceDetails.invoicedAmount - invoiceDetails.paidAmount
                                    ).toFixed(2)}
                                    placeholder="Amount Outstanding"
                                    readOnly
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={`mx-1`}>
                    {invoiceDetails.voidedAt ? (
                        <Col xs={12} md={6} lg={4}>
                            <FormGroup>
                                <Form.Label>Invoice Voided At</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="formattedVoidedAt"
                                    name="formattedVoidedAt"
                                    placeholder="Invoice Voided At"
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    ) : (
                        ''
                    )}
                    {invoiceDetails.voidedBy ? (
                        <Col xs={12} md={6} lg={4}>
                            <FormGroup>
                                <Form.Label>Invoice Voided By</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="formattedVoidedBy"
                                    name="formattedVoidedBy"
                                    placeholder="Invoice Voided By"
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    ) : (
                        ''
                    )}
                </Row>
                <Row className="mt-3 mx-1">
                    {/* Pickup Instructions*/}
                    <Col xs={6} md={5} lg={6}>
                        <FormGroup>
                            <Form.Label>Additional Details</Form.Label>
                            <div className="col-12 ">
                                <div>
                                    <div
                                        className="booking-wrap"
                                        dangerouslySetInnerHTML={{ __html: invoiceDetails.additionalDetails }}
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={6} md={5} lg={6}>
                        <FormGroup>
                            <Button
                                variant="info"
                                className="btn btn-primary text-uppercase ml-1 mb-1"
                                onClick={payInvoiceHandler}
                            >
                                PAY INVOICE
                            </Button>
                            <Button
                                variant="info"
                                className="btn btn-primary text-uppercase ml-1 mb-1"
                                onClick={handlePrint}
                            >
                                PRINT INVOICE
                            </Button>
                        </FormGroup>
                    </Col>

                    <Col xs={12}>
                        <h6>Transactions</h6>

                        <SimpleTable
                            data={transactions}
                            loading={loading}
                            mainColor={tripSettings?.mainColor}
                            onClick={() => {}}
                            rowStyle={rowStyle}
                            fieldNames={[
                                ['transactionType', 'Transaction Type'],
                                ['amount', 'Amount', (item) => `$${formatCurrency(item?.amount)}`],
                                ['transactionRef', 'Transaction ID'],
                                ['generalLedgerCode', 'General Ledger Code'],
                                ['date', 'Date'],
                            ]}
                            showFooter
                        />
                    </Col>
                </Row>

                {loading && <Loading loading={loading} />}
            </CustomAccordion>
        </MockupLayout>
    );
};

export default InvoiceDetails;
