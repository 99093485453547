export const initialStateBookings = {
    bookingDetails: {},
    addonAssignments: [],
    dispatches: [],
    vehicles: [],
    discounts: [],
    priorBookings: [],
    upcomingBookings: [],
    priorBookingsCopy: [],
    upcomingBookingsCopy: [],
    pastBookings: [],
    loading: false,
    errorMessage: null,
    transactions: [],
};

export const BookingsReducer = (state = initialStateBookings, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_BOOKINGS': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'FETCH_BOOKINGS_SUCCESS': {
            return {
                ...state,
                priorBookings: action.prior,
                priorBookingsCopy: action.prior,
                upcomingBookings: action.upcoming,
                upcomingBookingsCopy: action.upcoming,
                loading: false,
            };
        }
        case 'FETCH_BOOKINGS_ERROR': {
            return {
                ...state,
                errorMessage: action.error,
                loading: false,
            };
        }
        case 'REQUEST_BOOKING_DETAILS': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'FETCH_BOOKING_DETAILS_SUCCESS': {
            return {
                ...state,
                bookingDetails: action.details,
                addonAssignments: action.addonAssignments,
                dispatches: action.dispatches,
                loading: false,
            };
        }
        case 'FETCH_BOOKING_DETAILS_ERROR': {
            return {
                ...state,
                errorMessage: action.error,
                loading: false,
            };
        }
        case 'UPCOMING_BOOKINGS_SUCCESS':
            return {
                ...state,
                loading: false,
                upcomingBookings: action.payload,
                upcomingBookingsCopy: action.payload,
            };
        case 'UPCOMING_BOOKINGS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case 'PAST_BOOKINGS_SUCCESS':
            return {
                ...state,
                loading: false,
                pastBookings: action.payload,
                priorBookingsCopy: action.payload,
            };
        case 'PAST_BOOKINGS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case 'BOOKING_VEHICLES_DETAILS_SUCCESS':
            return {
                ...state,
                loading: false,
                vehicles: action.payload,
            };
        case 'BOOKING_DISCOUNTS_DETAILS_SUCCESS':
            return {
                ...state,
                loading: false,
                discounts: action.payload,
            };

        case 'FILTER_UPCOMING_BY_SEARCH_TEXT':
            let searchTextUpcoming = action.payload.searchText.toLowerCase();
            return {
                ...state,
                upcomingBookings: state.upcomingBookingsCopy.filter((booking) => {
                    return (
                        booking.bookingFormattedID?.toLowerCase().includes(searchTextUpcoming) ||
                        booking.tripReference?.toLowerCase().includes(searchTextUpcoming) ||
                        booking.companyName?.toLowerCase().includes(searchTextUpcoming) ||
                        booking.groupName?.toLowerCase().includes(searchTextUpcoming)
                    );
                }),
                loading: false,
            };
        case 'FILTER_PAST_BY_SEARCH_TEXT':
            let searchTextPast = action.payload.searchText.toLowerCase();
            return {
                ...state,
                pastBookings: state.priorBookingsCopy.filter((booking) => {
                    return (
                        booking.bookingId?.toLowerCase().includes(searchTextPast) ||
                        booking.tripReference?.toLowerCase().includes(searchTextPast) ||
                        booking.companyName?.toLowerCase().includes(searchTextPast) ||
                        booking.groupName?.toLowerCase().includes(searchTextPast)
                    );
                }),
                loading: false,
            };
        case 'BOOKING_TRANSACTION_SUCCESS': {
            return {
                ...state,
                transactions: action.payload,
                loading: false,
            };
        }
        case 'BOOKING_TRANSACTION_ERROR': {
            return {
                ...state,
                errorMessage: action.error,
                loading: false,
            };
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
