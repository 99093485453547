export const initialStatePaymentTerms = {
    paymentTerms: [],
    loading: false,
    errorMessage: null,
};

export const PaymentTermsReducer = (state = initialStatePaymentTerms, action) => {
    switch (action.type) {
        case 'REQUEST_PAYMENT_TERMS':
            return {
                ...state,
                loading: true,
            };
        case 'PAYMENT_TERMS_SUCCESS':
            return {
                ...state,
                paymentTerms: action.payload,
                loading: false,
            };
        case 'PAYMENT_TERMS_ERROR':
            return {
                ...state,
                errorMessage: action.error,
                loading: false,
            };
        default:
            return initialStatePaymentTerms;
    }
};
