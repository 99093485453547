import {
    getBookingsService,
    getBookingDetailsService,
    getBookingPaymentDetailsService,
    getBookingVehiclesDetailService,
    getBookingDiscountsService,
    getBookingTransactionService,
} from '../../services/bookingsService';

export async function getBookings(dispatch, upcomingFilter = 'next30Days', pastFilter = 'last30Days') {
    try {
        dispatch({ type: 'REQUEST_FETCH_BOOKINGS' });
        let response = await getBookingsService(upcomingFilter, pastFilter);
        if (response.data) {
            dispatch({ type: 'FETCH_BOOKINGS_SUCCESS', prior: response.data.prior, upcoming: response.data.upcoming });
            return response.data;
        }
        dispatch({ type: 'FETCH_BOOKINGS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'FETCH_BOOKINGS_ERROR', payload: error.message });
    }
}

export async function getBookingDiscounts(dispatch, bookingId) {
    try {
        let response = await getBookingDiscountsService(bookingId);
        if (response.data) {
            dispatch({ type: 'BOOKING_DISCOUNTS_DETAILS_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_BOOKINGS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'FETCH_BOOKINGS_ERROR', payload: error.message });
    }
}

export async function getBookingDetails(dispatch, id) {
    try {
        dispatch({ type: 'REQUEST_BOOKING_DETAILS' });
        let response = await getBookingDetailsService(id);
        if (response.data) {
            dispatch({
                type: 'FETCH_BOOKING_DETAILS_SUCCESS',
                details: response.data.details,
                addonAssignments: response.data.addonAssignments,
                dispatches: response.data.dispatches,
            });
            return response.data;
        }
        dispatch({ type: 'FETCH_BOOKING_DETAILS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'FETCH_BOOKING_DETAILS_ERROR', payload: error.message });
    }
}

export async function getBookingPaymentDetails(dispatch, id) {
    try {
        dispatch({ type: 'REQUEST_BOOKING_DETAILS' });
        let response = await getBookingPaymentDetailsService(id);
        if (response.data) {
            dispatch({ type: 'FETCH_BOOKING_PAYMENT_DETAILS_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'FETCH_BOOKING_PAYMENT_DETAILS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'FETCH_BOOKING_PAYMENT_DETAILS_ERROR', payload: error.message });
    }
}

export async function getUpcomingBookings(dispatch, { startDate, endDate }) {
    try {
        // dispatch({ type: 'REQUEST_CONTACTS_DETAILS' });
        let response = await getBookingsService({ type: 'upcoming', startDate, endDate });

        if (response.data) {
            dispatch({ type: 'UPCOMING_BOOKINGS_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'UPCOMING_BOOKINGS_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'UPCOMING_BOOKINGS_ERROR', error: 'Cound not fetch upcoming bookings, try again' });
    }
}

export async function getPastBookings(dispatch, { startDate, endDate }) {
    try {
        // dispatch({ type: 'REQUEST_CONTACTS_DETAILS' });
        let response = await getBookingsService({ type: 'past', startDate, endDate });

        if (response.data) {
            dispatch({ type: 'PAST_BOOKINGS_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'PAST_BOOKINGS_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'PAST_BOOKINGS_ERROR', error: 'Cound not fetch past bookings, try again' });
    }
}

export async function getBookingVehiclesDetails(dispatch, bookingId) {
    try {
        dispatch({ type: 'REQUEST_BOOKING_DETAILS' });
        let response = await getBookingVehiclesDetailService(bookingId, null);

        if (response.data) {
            dispatch({
                type: 'BOOKING_VEHICLES_DETAILS_SUCCESS',
                payload: response.data,
            });
            return response.data;
        }

        dispatch({
            type: 'FETCH_BOOKINGS_ERROR',
            error: response.data.errors[0],
        });
        return;
    } catch (error) {
        dispatch({ type: 'FETCH_BOOKINGS_ERROR', error: error });
    }
}

export async function getBookingTransaction(dispatch, bookingId) {
    try {
        let response = await getBookingTransactionService(bookingId);

        if (response.data) {
            dispatch({ type: 'BOOKING_TRANSACTION_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'BOOKING_TRANSACTION_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'BOOKING_TRANSACTION_ERROR', error: error });
    }
}
