export const initialStateAddonAssignment = {
    assignmentDetails: {},
    loading: false,
    addonAssignments: [],
    csvAddedAddons: [],
    addonRevenue: [],
    addonAssignment: null,
    errorMessage: null,
    pageInfo: {},
};

export const AddonAssignmentReducer = (state = initialStateAddonAssignment, action) => {
    switch (action.type) {
        case 'REQUEST_GET_ADDON_ASSIGNMENT':
            return {
                ...state,
                loading: true,
            };
        case 'GET_ADDON_ASSIGNMENT_SUCCESS':
            return {
                ...state,
                assignmentDetails: action.payload,
                loading: false,
            };

        case 'GET_ADDON_ASSIGNMENT_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        case 'REQUEST_GET_ADDON_REVENUE':
            return {
                ...state,
                loading: true,
            };
        case 'GET_ADDON_REVENUE_SUCCESS':
            return {
                ...state,
                addonRevenue: action.payload,
                loading: false,
            };

        case 'GET_ADDON_REVENUE_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'ADDON_ASSIGNMENTS_SUCCESS':
            return {
                ...state,
                loading: false,
                addonAssignments: action.payload,
            };
        case 'ADDON_ASSIGNMENTS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case 'DELETE_ADDON_ASSIGNMENTS_SUCCESS':
            return {
                ...state,
                loading: false,
                addonAssignments: state.addonAssignments.filter(
                    (a) => Number(a.recordId) !== Number(action.payload.deletedRecordId)
                ),
            };
        case 'DELETE_ADDON_ASSIGNMENTS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        case 'ADD_ADDON_ASSIGNMENTS_SUCCESS':
            return {
                ...state,
                addonAssignments: [...state.addonAssignments, action.payload],
                loading: false,
            };
        case 'ADD_ADDON_ASSIGNMENTS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        case 'EDIT_ADDON_ASSIGNMENTS_SUCCESS':
            return {
                ...state,
                addonAssignments: action.payload,
                loading: false,
            };
        case 'EDIT_ADDON_ASSIGNMENTS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'EDIT_ADDON_ASSIGNMENTS_LEDGER_CODE_SUCCESS':
            return {
                ...state,
                addonAssignment: action.payload,
                loading: false,
            };
        case 'EDIT_ADDON_ASSIGNMENTS_LEDGER_CODE_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'ADD_CSV_SUCCESS':
            return {
                ...state,
                csvAddedAddons: action.payload,
                loading: false,
            };
        case 'ADD_CSV_ERROR':
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case 'DELETE_ADDONS_ERROR':
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case 'DELETE_ADDONS_SUCCESS':
            return {
                ...state,
                loading: false,
            };
        case 'RESET_ADDON_ASSIGNMENTS':
            return initialStateAddonAssignment;
        case 'PAGE_INFO_CHANGE':
            return {
                ...state,
                pageInfo: action.payload,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
