import React, { useEffect, useState } from 'react';
import { Container, Modal, Row, Spinner, Button, FormGroup, Form, Col } from 'react-bootstrap';
import {
    getBookingDetails,
    useBookingState,
    useAccountState,
    getTripSettings,
    getBookingVehiclesDetails,
    useAddonAssignmentState,
    getAddonAssignments,
    getBookingDiscounts,
    useInvoicesState,
    getInvoiceDetails,
} from '../../../../Context';
import { printSignedBookingService } from '../../../../services/bookingsService';

import { formatToFixed, formatCurrency, calculateTotal } from '../../../../utils/helper';
import { sumBy } from 'lodash';
import Loading from '../../../../Components/Loading';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import BookingAssignment from '../../../../Components/BookingAssignment';
import MockupLayout from '../../../../Components/MockupLayout';
import CustomAccordion from '../../../../Components/CustomAccordion';
import SimpleTable from '../../../../Components/SimpleTable';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getBookingTransaction } from '../../../../Context/actions/bookingsActions';
dayjs.extend(utc);
dayjs.extend(timezone);

const BookingDetails = (props) => {
    const bookingId = props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const {
        bookingsDispatch,
        bookings: { bookingDetails, dispatches, vehicles, discounts, transactions },
    } = useBookingState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [loading, setLoading] = useState(false);
    const {
        addonAssignmentDispatch,
        addonAssignment: { addonAssignments },
    } = useAddonAssignmentState();

    const {
        invoiceDispatch,
        invoice: { invoiceDetails, errorMessage },
    } = useInvoicesState();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            const data = await getBookingDetails(bookingsDispatch, bookingId);
            await getBookingVehiclesDetails(bookingsDispatch, bookingId);
            await getBookingDiscounts(bookingsDispatch, bookingId);
            await getAddonAssignments(addonAssignmentDispatch, { bookingId: bookingId, isStoreProc: true });
            if (data?.details.invoiceID) {
                await getInvoiceDetails(invoiceDispatch, data.details.invoiceID);
            }
            await getBookingTransaction(bookingsDispatch, bookingId);

            setLoading(false);
        })();
    }, [bookingsDispatch, bookingId, addonAssignmentDispatch, AccountsDispatch]);

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    const totalRenderer = (data, key) => {
        return `$${formatCurrency(calculateTotal(data, key))}`;
    };

    const onVehicleClick = (item) => {
        props.history.push(`/bookings-vehicle-assignment/${item.id}?bookingId=${bookingId}`);
    };

    const handleCheckOutPageClick = async () => {
        window.open(
            `${window.location.origin}/p/booking-payment?bid=${bookingId}&ts=${new Date(bookingDetails?.createdAt).valueOf()}`,
            '_blank'
        );
    };

    //    const handleConfirmationPageClick = async () => {
    //        window.open(`${window.location.origin}/p/booking-confirmation?bid=${bookingId}&ts=${new Date().valueOf()}`, "_blank")
    //    }

    const handleprint = async () => {
        try {
            setLoading(true);
            const pdfData = await printSignedBookingService(bookingId, '', '', '', 1);
            setLoading(false);
            let blob = new Blob([pdfData.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            link.download = 'Booking (' + bookingDetails.bookingId + ')';
            link.click();
        } catch (e) {
            setLoading(false);
            window.swal('Not Found', 'No Contract Found!', 'error');
        }
    };

    const rowStyle = (item) => {
        let backgroundColor = '#FFFFFF';
        if (item.refundedAt) backgroundColor = '#CBC3E3';
        if (item.cancelledAt) backgroundColor = '#f08080';
        return { backgroundColor };
    };

    const getColumns = () => {
        let defaultColumns = [
            ['routeDescription', 'Route Description', null, 'TOTALS'],
            ['vehicleType', 'Vehicle Type'],
            ['vehicleID', 'Vehicle ID'],
        ];
        let driverDetailsColumns = [
            ['driver', 'Driver'],
            ['mobilePhone', 'Driver Mobile Phone Number'],
        ];
        let documentsTypesDetailsColumns = [
            ['price', 'Vehicle Price', (item) => `$${formatCurrency(item['price'])}`, totalRenderer(vehicles, 'price')],
            ['salesTax', 'Tax', (item) => `$${formatCurrency(item['salesTax'])}`, totalRenderer(vehicles, 'salesTax')],
            ['taxDescription', 'Tax Description'],
        ];

        // Determine which columns to include based on conditions
        const hasDriverDetails = bookingDetails.showDriverDetailsButton;
        const isNotDocumentType2 = bookingDetails.documentTypeID !== 2;

        let columns = [...defaultColumns];
        if (hasDriverDetails) {
            columns = [...columns, ...driverDetailsColumns];
        }
        if (isNotDocumentType2) {
            columns = [...columns, ...documentsTypesDetailsColumns];
        }

        return columns;
    };

    return (
        <MockupLayout
            title={`Booking ${bookingDetails && bookingDetails.bookingId ? bookingDetails.bookingId : 'Details'} ${
                bookingDetails.cancelledAt ? '(Cancelled)' : ''
            }`}
            titleIconClass={'fas fa-calendar-check'}
            textColor={tripSettings?.textColor}
            buttons={
                <>
                    <Button
                        onClick={() => {
                            props.history.push(`/bookings`);
                        }}
                        style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                    >
                        BACK TO BOOKINGS
                    </Button>
                </>
            }
        >
            <CustomAccordion
                id={1}
                title="Trip Details"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <Row
                    className={`mx-1 ${
                        bookingDetails.primaryContactFullName || bookingDetails.billingContactFullName ? '' : 'hidden'
                    }`}
                >
                    <dl
                        className={`col-12 col-md-4 details-list  ${
                            bookingDetails.primaryContactFullName ? '' : 'hidden'
                        }`}
                    >
                        <FormGroup>
                            <Form.Label>Primary Contact</Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    bookingDetails.primaryContactFullName ? bookingDetails.primaryContactFullName : ''
                                }
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl
                        className={`col-12 col-md-4 details-list  ${
                            bookingDetails.billingContactFullName ? '' : 'hidden'
                        }`}
                    >
                        <FormGroup>
                            <Form.Label>Billing Contact</Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    bookingDetails.billingContactFullName ? bookingDetails.billingContactFullName : ''
                                }
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
                <Row
                    className={`mx-1 ${
                        bookingDetails.bookingQuoteId ||
                        bookingDetails.operationalContactFullName ||
                        bookingDetails.salesPerson
                            ? ''
                            : 'hidden'
                    }`}
                >
                    <dl className={`col-12 col-md-4 details-list ${bookingDetails.bookingQuoteId ? '' : 'hidden'}`}>
                        {/*Booking Quote ID #*/}
                        <FormGroup>
                            <Form.Label>Associated Quote ID #</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.bookingQuoteId ? bookingDetails.bookingQuoteId : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl
                        className={`col-12 col-md-4 details-list ${
                            bookingDetails.operationalContactFullName ? '' : 'hidden'
                        }`}
                    >
                        <FormGroup>
                            <Form.Label>Operations Contact</Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    bookingDetails.operationalContactFullName
                                        ? bookingDetails.operationalContactFullName
                                        : ''
                                }
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-4 details-list ${bookingDetails.salesPerson ? '' : 'hidden'}`}>
                        <FormGroup>
                            <Form.Label>Salesperson</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.salesPerson ? bookingDetails.salesPerson : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
                <Row className={`mx-1`}>
                    <dl className={`col-12 col-md-3 details-list`}>
                        {/*Trip Description*/}
                        <FormGroup>
                            <Form.Label>Trip Reference</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.tripReference ? bookingDetails.tripReference : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-3 details-list`}>
                        {/*Trip Description*/}
                        <FormGroup>
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.groupName ? bookingDetails.groupName : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-3 details-list `}>
                        {/*Purchase Order #*/}
                        <FormGroup>
                            <Form.Label>Purchase Order #</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.purchaseOrder ? bookingDetails.purchaseOrder : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    {bookingDetails.isSignedContract && (
                        <dl
                            className={`col-12 col-md-3 details-list `}
                            style={{ alignSelf: 'center', textAlign: 'center' }}
                        >
                            {/*View Signed Contract #*/}
                            <Button
                                onClick={handleprint}
                                style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                            >
                                View Signed Contract
                            </Button>
                        </dl>
                    )}
                    {bookingDetails.showDriverDetailsButton && (
                        <dl
                            className={`col-12 col-md-3 details-list `}
                            style={{ alignSelf: 'center', textAlign: 'center' }}
                        >
                            <Button
                                onClick={() => props.history.push(`/bookings/driver-details/${bookingId}`)}
                                style={{
                                    backgroundColor: `${tripSettings.buttonColor}`,
                                    borderColor: 'transparent',
                                }}
                            >
                                Driver Details
                            </Button>
                        </dl>
                    )}
                </Row>
            </CustomAccordion>

            <CustomAccordion
                id={4}
                title={'VEHICLES'}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                <SimpleTable
                    data={vehicles ? vehicles : []}
                    onClick={() => {}}
                    bordered
                    mainColor={tripSettings?.mainColor}
                    showFooter={!(bookingDetails.documentTypeID === 2)}
                    fieldNames={getColumns()}
                />
            </CustomAccordion>

            {!(bookingDetails.documentTypeID === 2) ? (
                <CustomAccordion
                    id={5}
                    title={'ADDONS'}
                    collapseItem={collapseItem}
                    handleItemCollapse={handleItemCollapse}
                    sectionColor={tripSettings?.sectionColor}
                >
                    <SimpleTable
                        data={addonAssignments}
                        bordered
                        mainColor={tripSettings?.mainColor}
                        showFooter={true}
                        fieldNames={[
                            [
                                'description',
                                'Description',
                                (item) => (item.overrideDescription ? item.overrideDescription : item.description),
                                'TOTALS',
                            ],
                            ['cost', 'Cost'],
                            ['quantity', 'Quantity'],
                            [
                                'price',
                                'Addon Price',
                                (item) => `$${formatCurrency(item['price'])}`,
                                totalRenderer(addonAssignments, 'price'),
                            ],
                            [
                                'salesTax',
                                'Tax',
                                (item) => `$${formatCurrency(item['salesTax'])}`,
                                totalRenderer(addonAssignments, 'salesTax'),
                            ],
                            ['salesTaxDescription', 'Tax Description'],
                        ]}
                    />
                </CustomAccordion>
            ) : (
                <></>
            )}
            {
                <CustomAccordion
                    id={10}
                    title={'DISCOUNTS'}
                    collapseItem={collapseItem}
                    handleItemCollapse={handleItemCollapse}
                    sectionColor={tripSettings?.sectionColor}
                >
                    <SimpleTable
                        data={discounts}
                        mainColor={tripSettings?.mainColor}
                        bordered
                        showFooter={true}
                        fieldNames={[
                            ['description', 'Description', null, 'TOTALS'],
                            ['code', 'Code'],
                            ['fAmount', 'Amount', null, totalRenderer(discounts, 'amount')],
                        ]}
                    />
                </CustomAccordion>
            }

            {/*Routes (Booking Vehicle Assignments)*/}
            <CustomAccordion
                id={2}
                key={2}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                title="Routes"
            >
                <div className="px-md-4 py-2 overflow-auto">
                    <BookingAssignment
                        cancelled={bookingDetails.cancelledAt}
                        mainColor={tripSettings?.mainColor}
                        bookingId={bookingId}
                        onRowClick={onVehicleClick}
                    />
                </div>
            </CustomAccordion>

            <CustomAccordion
                id={3}
                title="Financial"
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                collapseItem={collapseItem}
            >
                {bookingDetails?.invoiceID && (
                    <Row>
                        <Col>
                            <Form.Label>View Invoice :</Form.Label>
                            <Link to={`/invoices/${bookingDetails.invoiceID}`}>
                                <span className="font-weight-bold ml-2">
                                    {invoiceDetails?.invoiceNumber}
                                    <i className="fa fa-link ml-2" />
                                </span>
                            </Link>
                        </Col>
                    </Row>
                )}
                <Row className="mx-1">
                    <dl className={`col-6 col-md-3 details-list}`}>
                        {/*Payment Status*/}
                        {bookingDetails.paymentStatus ? (
                            <>
                                <FormGroup>
                                    <Form.Label>Payment Status</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={bookingDetails.paymentStatus ? bookingDetails.paymentStatus : ''}
                                        readOnly
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            ''
                        )}

                        {/*Deposit Due Date*/}
                        {bookingDetails.depositDueDate ? (
                            <>
                                <FormGroup>
                                    <Form.Label>Deposit Due Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={bookingDetails.depositDueDate ? bookingDetails.depositDueDate : ''}
                                        readOnly
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            ''
                        )}
                        {/*Deposit Amount*/}
                        {bookingDetails.depositAmount ? (
                            <>
                                <FormGroup>
                                    <Form.Label>Deposit Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={bookingDetails.depositAmount ? bookingDetails.depositAmount : 0}
                                        readOnly
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            ''
                        )}
                    </dl>
                    <dl className={`col-6 col-md-3 details-list`}>
                        {/*Payment Status*/}
                        {bookingDetails.finalPaymentDueDate ? (
                            <>
                                <FormGroup>
                                    <Form.Label>Final Payment Due Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={
                                            bookingDetails.finalPaymentDueDate ? bookingDetails.finalPaymentDueDate : ''
                                        }
                                        readOnly
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            ''
                        )}
                        {/*Total Before Tax*/}
                        <FormGroup>
                            <Form.Label>Total Before Tax</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.totalBeforeTax ? bookingDetails.totalBeforeTax : 0}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-6 col-md-3 details-list`}>
                        {/*Total Tax*/}
                        <FormGroup>
                            <Form.Label>Total Tax</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.totalTax ? bookingDetails.totalTax : 0}
                                readOnly
                            />
                        </FormGroup>
                        {/*Amount Paid*/}
                        <FormGroup>
                            <Form.Label>Amount Paid</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.amountPaid ? bookingDetails.amountPaid : 0}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-6 col-md-3 details-list`}>
                        {/*Total After Tax*/}
                        <FormGroup>
                            <Form.Label>Total After Tax</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.totalAfterTax ? bookingDetails.totalAfterTax : 0}
                                readOnly
                            />
                        </FormGroup>
                        {/*Amount Outstanding*/}
                        <FormGroup>
                            <Form.Label>Amount Outstanding</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.amountOutstanding ? bookingDetails.amountOutstanding : 0}
                                readOnly
                            />
                        </FormGroup>
                        {bookingDetails?.amountOutstanding &&
                        bookingDetails.amountOutstanding > 0 &&
                        !bookingDetails.disablePayment &&
                        bookingDetails.paymentTermID &&
                        Number(bookingDetails.paymentTermID) === 1 ? (
                            <FormGroup>
                                <Form.Label>Make Payment</Form.Label>
                                <dd>
                                    <Button variant="primary" onClick={handleCheckOutPageClick}>
                                        Make Payment
                                    </Button>
                                </dd>
                            </FormGroup>
                        ) : (
                            ''
                        )}
                    </dl>
                </Row>
                <Row className="my-2">
                    <Col xs={12}>
                        <h6>Transactions</h6>
                        <SimpleTable
                            data={transactions}
                            mainColor={tripSettings?.mainColor}
                            onClick={() => {}}
                            rowStyle={rowStyle}
                            fieldNames={[
                                ['transactionTypeDescription', 'Transaction Type'],
                                ['transactionRefNumber', 'Transaction Reference'],
                                ['transactionRef', 'Transaction ID'],
                                ['amount', 'Amount', (item) => `$${formatCurrency(item['amount'])}`],
                                ['date', 'Date'],
                            ]}
                        />
                    </Col>
                </Row>
            </CustomAccordion>
            {loading && <Loading loading={loading} />}
            {loading && (
                <Container className="mb-5 text-center">
                    <Modal show={true} animation={false} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </Container>
            )}
        </MockupLayout>
    );
};

export default BookingDetails;
