import React, { useEffect, useState } from 'react';
import { getVehicleAssignments, useVehicleAssignmentsState } from '../../Context';
import ListingTable from '../SimpleTable';

const BookingAssignment = ({ bookingId, onRowClick, cancelled, mainColor }) => {
    const { vehicleAssignmentsDispatch } = useVehicleAssignmentsState();
    const [loading, setLoading] = useState(false);
    const [bookingVehicleAssignments, setBookingVehicleAssignments] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = await getVehicleAssignments(vehicleAssignmentsDispatch, bookingId);
            setBookingVehicleAssignments(data);
            setLoading(false);
        })();
    }, [bookingId, vehicleAssignmentsDispatch]);

    const rowStyle = () => {
        return { backgroundColor: cancelled ? '#f08080' : '#FFFFFF' };
    };
    return (
        <>
            <ListingTable
                pageInfo={{
                    first: 0,
                    last: Array.isArray(bookingVehicleAssignments) ? bookingVehicleAssignments.length : 0,
                }}
                onClick={onRowClick}
                data={bookingVehicleAssignments ? bookingVehicleAssignments : []}
                loading={loading}
                mainColor={mainColor}
                rowStyle={rowStyle}
                fieldNames={[
                    ['bookingId', 'Booking ID'],
                    ['routeDescription', 'Route Description'],
                    ['firstDepartureTime', 'First Pick-Up Time', false],
                    ['firstPickupLocation', 'First Pick-Up Location'],
                    ['lastArrivalTime', 'Final Drop-Off Time', false],
                ]}
            />
        </>
    );
};

export default BookingAssignment;
