import { GetPaymentTermsService } from '../../services/paymentTermsService';

export const getPaymentTerms = async (dispatch) => {
    try {
        dispatch({ type: 'REQUEST_PAYMENT_TERMS' });
        let response = await GetPaymentTermsService();
        if (response.data) {
            dispatch({ type: 'PAYMENT_TERMS_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'PAYMENT_TERMS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'PAYMENT_TERMS_ERROR', error: error.response?.data?.error });
    }
};
