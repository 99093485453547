import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

const CustomAccordion = ({
    id,
    title,
    handleItemCollapse,
    collapseItem,
    children,
    sectionColor,
    titleFloatRight = null,
}) => {
    return (
        <Card className="w-100 mb-2 p-0">
            <Accordion defaultActiveKey={3}>
                <Accordion.Toggle
                    id={id}
                    as={Card.Header}
                    eventKey={3}
                    onClick={handleItemCollapse}
                    style={{ backgroundColor: `${sectionColor}` }}
                >
                    {title}
                    <i
                        id={id}
                        className={
                            !collapseItem[id] ? 'fas fa-minus-square float-right' : 'fas fa-plus-square float-right'
                        }
                    />
                    {titleFloatRight && <div className="float-right mr-4">{titleFloatRight}</div>}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={3} animation="false">
                    <Card.Body className="p-1">{children}</Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </Card>
    );
};

export default CustomAccordion;
