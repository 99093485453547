import React from 'react';
import { formatCurrency, totalAmountValues } from '../../utils/helper';
import CustomAccordion from '../CustomAccordion';
import { Form, FormGroup, Row } from 'react-bootstrap';
import SimpleTable from '../SimpleTable';

const VehicleInformation = ({
    data,
    collapseItem,
    handleItemCollapse,
    sectionColor,
    loading,
    mainColor,
    quoteDocumentTypeID,
}) => {
    const renderTotal = (item) => {
        return `$${formatCurrency(Number(item['total']))}`;
    };
    return (
        <CustomAccordion
            id={2}
            title="Vehicle Information"
            collapseItem={collapseItem}
            handleItemCollapse={handleItemCollapse}
            sectionColor={sectionColor}
        >
            <Row className="mx-1">
                <dl className="col-6 col-md-4 details-list">
                    {/*Total Available Capacity*/}
                    <FormGroup>
                        <Form.Label>Total Available Capacity</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.totalAvailableCapacity ? data.totalAvailableCapacity : ''}
                            readOnly
                        />
                    </FormGroup>
                </dl>
            </Row>
            <SimpleTable
                loading={loading}
                onClick={() => {}}
                data={data.vehicleRequests}
                mainColor={mainColor}
                bordered={false}
                showFooter={!(quoteDocumentTypeID === 2)}
                fieldNames={
                    !(quoteDocumentTypeID === 2)
                        ? [
                              ['displayName', 'Vehicle Description', '', 'TOTAL'],
                              ['quantity', 'Quantity'],
                              ['capacity', 'Available Capacity'],
                              ['total', 'Total', renderTotal, totalAmountValues(data.vehicleRequests, 'total')],
                          ]
                        : [
                              ['displayName', 'Vehicle Description', '', 'TOTAL'],
                              ['quantity', 'Quantity'],
                              ['capacity', 'Available Capacity'],
                          ]
                }
            />
        </CustomAccordion>
    );
};

export default VehicleInformation;
