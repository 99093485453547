import React, { useLayoutEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../Components/Footer';
import Loading from '../../Components/Loading';
import Header from '../../Components/MockupHeader/index';
import MobileHeader from '../../Components/MobileMockupHeader/index';
import './Mockup.scss';
import { setMockupTheme, useMockupThemeState, useAccountState, getTripSettings } from '../../Context';
import { getLogo } from '../../Context/actions/mockupThemeActions';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory } from 'react-router-dom';
import MobileFooter from '../../Components/mobileFooter';

// Variable Used
// 1)  name:- primaryTextColor,
//     usedFor:- linkText, headerIcons
//
// 2)  name:- backgroundColor,
//     usedFor:- backgroundColor for card-header, accordion-header
//
// 3)  name:- fontColor,
//     usedFor:- textColor used in conjunction with backgroundColor

const Mockup = ({ children, mobileAndTabletCheck }) => {
    const {
        mockupThemeDispatch,
        mockupTheme: { backgroundColor, fontColor, primaryTextColor, logo, footerLogo },
    } = useMockupThemeState();
    const [contactLogo, setContactLogo] = useState('placeholder');
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [loading, setLoading] = useState(false);
    const navigate = useHistory();

    useLayoutEffect(() => {
        (async function () {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await setMockupTheme(mockupThemeDispatch);
            const tempLogo = await getLogo(mockupThemeDispatch);
            setContactLogo(tempLogo);
            setLoading(false);
        })();
    }, [mockupThemeDispatch]);

    const MenuItems = [
        {
            name: 'Quotes',
            children: [],
            icon: 'fa-file-alt',
            active: true,
            webUrl: '/quotes',
            path: '/quotes',
            isEnabled: true,
            uuid: 'ad4123ff-d75b-4730-baed-763976494031',
        },
        {
            name: 'Bookings',
            children: [],
            icon: 'fa-calendar-check',
            active: true,
            webUrl: '/bookings',
            path: '/bookings',
            isEnabled: true,
            uuid: '6667e29d-7a49-402e-ac38-33018083a31b',
        },
        {
            name: 'Invoices',
            children: [],
            icon: 'fas fa-file-invoice',
            active: true,
            webUrl: '/invoices',
            path: '/invoices',
            isEnabled: true,
            uuid: '9d55d399-53a8-452c-ac8b-163acba21482',
        },
    ];

    const getDefaultActiveNav = () => {
        if (window.location.pathname.includes('quotes')) return 'Quotes';
        else if (window.location.pathname.includes('bookings')) return 'Bookings';
        else return 'Invoices';
    };

    return !loading ? (
        <>
            <div>
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                    :root {
                        --themeBackgroundColor: ${backgroundColor};
                        --themeFontColor: ${fontColor};
                        --primaryTextColor: ${primaryTextColor};
                    }
                `,
                    }}
                />
                <Container fluid className="p-0 fullHeight">
                    {!mobileAndTabletCheck ? (
                        <Header menuItems={MenuItems} logo={contactLogo || logo} />
                    ) : (
                        <MobileHeader menuItems={MenuItems} logo={contactLogo || logo} />
                    )}
                    {mobileAndTabletCheck && (
                        <Tabs
                            defaultActiveKey={getDefaultActiveNav()}
                            id="uncontrolled-tab-example"
                            className="main-tabs"
                        >
                            <Tab
                                eventKey="Quotes"
                                title="Quotes"
                                onEnter={() => {
                                    navigate.push(`/quotes`);
                                }}
                            ></Tab>
                            <Tab
                                eventKey="Bookings"
                                title="Bookings"
                                onEnter={() => {
                                    navigate.push(`/bookings`);
                                }}
                            ></Tab>
                            <Tab
                                eventKey="Invoices"
                                title="Invoices"
                                onEnter={() => {
                                    navigate.push(`/invoices`);
                                }}
                            ></Tab>
                        </Tabs>
                    )}
                    <div>{children}</div>
                    {!mobileAndTabletCheck ? (
                        <Footer fixed={false} logo={footerLogo} loading={loading} mainColor={tripSettings?.mainColor} />
                    ) : (
                        <MobileFooter
                            companylogo={contactLogo || logo}
                            fixed={true}
                            logo={footerLogo}
                            loading={loading}
                            mainColor={tripSettings?.mainColor}
                        />
                    )}
                </Container>
            </div>
        </>
    ) : (
        <Loading loading={loading} />
    );
};

export default Mockup;
