export const initialStateInvoice = {
    invoices: [],
    invoicesCopy: [],
    invoiceDetails: {},
    invoiceDeliveryMethods: [],
    invoiceFrequency: [],
    loading: false,
    errorMessage: null,
    pageInfo: {},
    printBlob: null,
    mail: {},
    transactions: [],
};

export const InvoiceReducer = (state = initialStateInvoice, action) => {
    switch (action.type) {
        case 'REQUEST_CREATE_INVOICE':
            return {
                ...state,
                loading: true,
                errorMessage: null,
                invoiceDetails: null,
                invoices: [],
            };
        case 'CREATE_INVOICE_SUCCESS':
            return {
                ...state,
                loading: false,
                invoiceDetails: action.payload,
            };
        case 'CREATE_INVOICE_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'REQUEST_GET_INVOICES':
            return {
                ...state,
                loading: true,
                errorMessage: null,
                invoiceDetails: null,
                invoices: [],
            };
        case 'GET_INVOICES_SUCCESS':
            return {
                ...state,
                loading: false,
                invoices: action.payload,
                invoicesCopy: action.payload,
            };
        case 'FILTER_BY_SEARCH_TEXT':
            let searchText = action.payload.searchText.toLowerCase();
            return {
                ...state,
                invoices: state.invoicesCopy.filter((invoice) => {
                    return (
                        String(invoice.invoiceNumber)?.toLowerCase().includes(searchText) ||
                        invoice.company?.toLowerCase().includes(searchText)
                    );
                }),
                loading: false,
            };
        case 'GET_INVOICES_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'GET_INVOICES_DETAILS_EMAIL_SUCCESS':
            return {
                ...state,
                loading: false,
                mail: action.payload,
            };
        case 'GET_INVOICES_DETAILS_EMAIL_ERROR':
            return {
                ...state,
                loading: false,
            };
        case 'REQUEST_GET_INVOICE':
            return {
                ...state,
                loading: true,
                errorMessage: null,
                invoiceDetails: null,
                invoices: state.invoices,
            };
        case 'GET_INVOICE_SUCCESS':
            return {
                ...state,
                loading: false,
                invoiceDetails: action.payload,
            };
        case 'GET_INVOICE_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'EDIT_INVOICE_SUCCESS':
            return {
                ...state,
                loading: false,
                invoiceDetails: action.payload,
            };
        case 'EDIT_INVOICE_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'DELETE_INVOICE_SUCCESS':
            return {
                ...state,
                loading: false,
                invoiceDetails: null,
            };
        case 'DELETE_INVOICE_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'GET_INVOICES_DELIVERY_METHODS_SUCCESS':
            return {
                ...state,
                loading: true,
                errorMessage: null,
                invoiceDeliveryMethods: action.payload,
            };
        case 'GET_INVOICES_DELIVERY_METHODS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'GET_INVOICES_FREQUENCY_SUCCESS':
            return {
                ...state,
                loading: true,
                errorMessage: null,
                invoiceFrequency: action.payload,
            };
        case 'GET_INVOICES_FREQUENCY_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'SET_FILTERED_INVOICES':
            return {
                ...state,
                loading: false,
                invoices: action.payload.filterInvoices,
            };
        case 'PAGE_INFO_CHANGE':
            return {
                ...state,
                pageInfo: action.payload,
            };
        case 'REQUEST_GET_INVOICE_PRINT':
            return {
                ...state,
                loading: true,
                errorMessage: null,
            };
        case 'GET_INVOICE_PRINT_SUCCESS':
            return {
                ...state,
                loading: false,
                printBlob: action.payload,
            };
        case 'GET_INVOICE_PRINT_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'REQUEST_GET_INVOICE_TRANSACTION':
            return {
                ...state,
                loading: true,
            };
        case 'GET_INVOICE_TRANSACTION_SUCCESS':
            return {
                ...state,
                loading: false,
                transactions: action.payload,
            };
        case 'GET_INVOICE_TRANSACTION_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'RESET_INVOICE':
            return initialStateInvoice;
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
