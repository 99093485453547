import { useReducer } from 'react';

// Reducers
import { initialStateMockupTheme, MockupThemeReducer } from '../Context/reducer/mockupThemeReducer';
import { initialStateBookings, BookingsReducer } from '../Context/reducer/bookingsReducer';
import { initialStateQuotes, QuotesReducer } from '../Context/reducer/quotesReducer';
import { initialStateGeneralLedgerCode, GeneralLedgerCodeReducer } from '../Context/reducer/generalLedgerCodeReducer';
import { initialStateTransactions, TransactionsReducer } from '../Context/reducer/transactionsReducer';
import { initialStateSettings, AccountSettingReducer } from '../Context/reducer/accountSettingsReducer';
import { initialStateCompanies, CompanyReducer } from '../Context/reducer/companyReducer';
import { initialStatePayment, MakePaymentReducer } from '../Context/reducer/authorizeNetReducer';
import { initialStateTransactionTypes, TransactionTypesReducer } from '../Context/reducer/transactionTypesReducer';
import { initialStatePaymentTerms, PaymentTermsReducer } from '../Context/reducer/paymentTermsReducer';
import { initialStateAddonAssignment, AddonAssignmentReducer } from '../Context/reducer/addonAssignmentReducer';

// Context
import {
    MockupThemeContext,
    BookingContext,
    QuotesContext,
    GeneralLedgerCodeContext,
    TransactionsContext,
    AccountsContext,
    CompanyContext,
    MakePaymentContext,
    TransactionTypesContext,
    PaymentTermsContext,
    AddonAssignmentContext,
} from '../Context';

// Views
import Mockup from '../Containers/layouts/Mockup';
import MockupRoute from './MockupRoute';
import MockupBookings from '../Containers/views/mockups/Bookings';
import MockupQuotes from '../Containers/views/mockups/Quotes';
import BookingDetails from '../Containers/views/mockups/BookingDetails';
import QuoteDetails from '../Containers/views/mockups/QuoteDetails';
import { initialStateVehicleAssignment, VehicleAssignmentReducer } from '../Context/reducer/vehicleAssignmentsReducer';
import { InvoicesContext, VehicleAssignmentsContext } from '../Context/context';
import BookingVehicleAssignments from '../Containers/views/mockups/BookingVehicleAssignments';
import { initialStateInvoice, InvoiceReducer } from '../Context/reducer/invoiceReducer';
import MockupInvoices from '../Containers/views/mockups/Invoices';
import InvoiceDetails from '../Containers/views/mockups/InvoiceDetails';
//mobileViews

// import Index from "../Containers/views";
import MobileMockupBookings from '../Containers/views/mobileMockups/Bookings';
import MobileMockupQuotes from '../Containers/views/mobileMockups/Quotes';
import MobileBookingDetails from '../Containers/views/mobileMockups/BookingDetails';
import MobileQuoteDetails from '../Containers/views/mobileMockups/QuoteDetails';
import MobileBookingVehicleAssignments from '../Containers/views/mobileMockups/BookingVehicleAssignments';
import MobileMockupInvoices from '../Containers/views/mobileMockups/Invoices';

import { mobileAndTabletCheck } from '../utils/helper';
import BookingDriverDetails from '../Containers/views/mockups/DriverDetails';
import MobileBookingDriverDetails from '../Containers/views/mobileMockups/DriverDetails';

const getComponent = (WebComponent, MobComponent) => {
    return !mobileAndTabletCheck() ? WebComponent : MobComponent;
};

const MockupRoutes = () => {
    const [mockupTheme, mockupThemeDispatch] = useReducer(MockupThemeReducer, initialStateMockupTheme);
    const [bookings, bookingsDispatch] = useReducer(BookingsReducer, initialStateBookings);
    const [quotes, quotesDispatch] = useReducer(QuotesReducer, initialStateQuotes);
    const [generalLedgerCode, generalLedgerCodeDispatch] = useReducer(
        GeneralLedgerCodeReducer,
        initialStateGeneralLedgerCode
    );
    const [transactions, transactionsDispatch] = useReducer(TransactionsReducer, initialStateTransactions);
    const [vehicleAssignments, vehicleAssignmentsDispatch] = useReducer(
        VehicleAssignmentReducer,
        initialStateVehicleAssignment
    );
    const [invoice, invoiceDispatch] = useReducer(InvoiceReducer, initialStateInvoice);
    const [tripSettings, AccountsDispatch] = useReducer(AccountSettingReducer, initialStateSettings);
    const [companies, companyDispatch] = useReducer(CompanyReducer, initialStateCompanies);
    const [payments, paymentDispatch] = useReducer(MakePaymentReducer, initialStatePayment);
    const [transactionTypes, transactionTypesDispatch] = useReducer(
        TransactionTypesReducer,
        initialStateTransactionTypes
    );
    const [paymentTerms, paymentTermsDispatch] = useReducer(PaymentTermsReducer, initialStatePaymentTerms);
    const [addonAssignment, addonAssignmentDispatch] = useReducer(AddonAssignmentReducer, initialStateAddonAssignment);

    return (
        <MockupThemeContext.Provider value={{ mockupTheme, mockupThemeDispatch }}>
            <AccountsContext.Provider value={{ tripSettings, AccountsDispatch }}>
                <Mockup mobileAndTabletCheck={mobileAndTabletCheck()}>
                    <BookingContext.Provider value={{ bookings, bookingsDispatch }}>
                        <QuotesContext.Provider value={{ quotes, quotesDispatch }}>
                            <GeneralLedgerCodeContext.Provider value={{ generalLedgerCode, generalLedgerCodeDispatch }}>
                                <TransactionsContext.Provider value={{ transactions, transactionsDispatch }}>
                                    <VehicleAssignmentsContext.Provider
                                        value={{ vehicleAssignments, vehicleAssignmentsDispatch }}
                                    >
                                        <InvoicesContext.Provider value={{ invoice, invoiceDispatch }}>
                                            <CompanyContext.Provider value={{ companies, companyDispatch }}>
                                                <MakePaymentContext.Provider value={{ payments, paymentDispatch }}>
                                                    <PaymentTermsContext.Provider
                                                        value={{ paymentTerms, paymentTermsDispatch }}
                                                    >
                                                        <TransactionTypesContext.Provider
                                                            value={{ transactionTypes, transactionTypesDispatch }}
                                                        >
                                                            <AddonAssignmentContext.Provider
                                                                value={{ addonAssignment, addonAssignmentDispatch }}
                                                            >
                                                                <MockupRoute
                                                                    path={'/bookings'}
                                                                    exact
                                                                    component={getComponent(
                                                                        MockupBookings,
                                                                        MobileMockupBookings
                                                                    )}
                                                                />
                                                                <MockupRoute
                                                                    path={'/bookings/:id'}
                                                                    exact
                                                                    component={getComponent(
                                                                        BookingDetails,
                                                                        MobileBookingDetails
                                                                    )}
                                                                />
                                                                <MockupRoute
                                                                    path={'/bookings/driver-details/:id'}
                                                                    exact
                                                                    component={getComponent(
                                                                        BookingDriverDetails,
                                                                        MobileBookingDriverDetails
                                                                    )}
                                                                />
                                                                <MockupRoute
                                                                    path={'/quotes'}
                                                                    exact
                                                                    component={getComponent(
                                                                        MockupQuotes,
                                                                        MobileMockupQuotes
                                                                    )}
                                                                />
                                                                <MockupRoute
                                                                    path={'/quotes/:id'}
                                                                    exact
                                                                    component={getComponent(
                                                                        QuoteDetails,
                                                                        MobileQuoteDetails
                                                                    )}
                                                                />
                                                                <MockupRoute
                                                                    path={'/bookings-vehicle-assignment/:id'}
                                                                    exact
                                                                    component={getComponent(
                                                                        BookingVehicleAssignments,
                                                                        MobileBookingVehicleAssignments
                                                                    )}
                                                                />
                                                                <MockupRoute
                                                                    path={'/invoices'}
                                                                    exact
                                                                    component={getComponent(
                                                                        MockupInvoices,
                                                                        MobileMockupInvoices
                                                                    )}
                                                                />
                                                                <MockupRoute
                                                                    path={'/invoices/:id'}
                                                                    exact
                                                                    render={(props) => (
                                                                        <InvoiceDetails
                                                                            {...props}
                                                                            isMobile={mobileAndTabletCheck()}
                                                                        />
                                                                    )}
                                                                />
                                                            </AddonAssignmentContext.Provider>
                                                        </TransactionTypesContext.Provider>
                                                    </PaymentTermsContext.Provider>
                                                </MakePaymentContext.Provider>
                                            </CompanyContext.Provider>
                                        </InvoicesContext.Provider>
                                    </VehicleAssignmentsContext.Provider>
                                </TransactionsContext.Provider>
                            </GeneralLedgerCodeContext.Provider>
                        </QuotesContext.Provider>
                    </BookingContext.Provider>
                    {/* <Route path="/" exact component={Index} /> */}
                </Mockup>
            </AccountsContext.Provider>
        </MockupThemeContext.Provider>
    );
};

export default MockupRoutes;
