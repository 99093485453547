import React, { useEffect, useState } from 'react';
import { formatCurrency } from '../../../../utils/helper';
import { getInvoices, useInvoicesState, filterInvoices, getTripSettings, useAccountState } from '../../../../Context';
import './index.scss';
import { Col, Form, Row } from 'react-bootstrap';
import Loading from '../../../../Components/Loading';
import moment from 'moment';

const MockupInvoices = ({ history }) => {
    const {
        invoice: { invoices, invoicesCopy },
        invoiceDispatch,
    } = useInvoicesState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const styles = { cancelled: { backgroundColor: 'rgb(240, 128, 128)' } };
    const [invoiceSelectedFilter, setInvoiceSelectedFilter] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dueSelectedFilter, setDueSelectedFilter] = useState('all');

    let pastFilterOptions = [
        { title: 'All', value: 'all' },
        { title: 'Current', value: 'current' },
        { title: '1 - 30 Days Past Due', value: '1-30due' },
        { title: '31 - 60 Days Past Due', value: '31-60due' },
        { title: '61 - 90 Days Past Due', value: '61-90due' },
        { title: '91+ Days Past Due', value: 'above90' },
    ];

    let InvoicefilterOptions = [
        { title: 'Today', value: '0' },
        { title: 'Yesterday', value: '1' },
        { title: 'Last 7 Days', value: '7' },
        { title: 'Last 30 Days', value: '30' },
        { title: 'Last 60 Days', value: '60' },
        { title: 'Last 180 Days', value: '180' },
        { title: 'All Time', value: '36500' },
    ];

    const getStartDate = (dayValue = '0') => {
        return dayValue === '1'
            ? moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('day').subtract(Number(dayValue), 'days').format('YYYY-MM-DD HH:mm:ss');
    };

    const getEndDate = (dayValue = '0') => {
        return dayValue === '1'
            ? moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    };

    const onStatusFilterChange = async (e) => {
        setDueSelectedFilter(e.target.value);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getInvoices(invoiceDispatch, getStartDate(invoiceSelectedFilter), getEndDate(invoiceSelectedFilter));
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AccountsDispatch, invoiceDispatch]);

    useEffect(() => {
        filterInvoices(invoiceDispatch, invoicesCopy, invoiceSelectedFilter, dueSelectedFilter);
    }, [invoiceDispatch, invoiceSelectedFilter, invoicesCopy, dueSelectedFilter]);

    const rowStyle = (item) => {
        return styles[item.cancelledAt ? 'cancelled' : ''];
    };

    const onRowClick = (item) => {
        history.push(`/invoices/${item.id}`);
    };

    const renderTotal = (item, key) => {
        return item[key] ? `$${formatCurrency(Number(item[key]))}` : '$0.00';
    };

    const onPaymentClick = (e, item) => {
        window.open(
            `${window.location.origin}/p/pay-invoice?ts=${moment(item.createdAt).unix()}&invoiceId=${item.id}`,
            '_blank'
        );
    };

    const onInvoiceFilterChange = async (e) => {
        setLoading(true);
        setInvoiceSelectedFilter(e.target.value);
        await getInvoices(invoiceDispatch, getStartDate(e.target.value), getEndDate(e.target.value));
        setLoading(false);
    };

    const renderMakePayment = (item) => {
        return item.invoicedAmount - item.paidAmount > 0 && !item.disableOnlinePayment ? (
            <i className="fa fa-credit-card" onClick={(e) => onPaymentClick(e, item)} />
        ) : (
            ''
        );
    };

    return !loading && tripSettings ? (
        <div className="w-100 content-section">
            <div className="px-3 mt-2">
                <Row>
                    <Col style={{ display: 'flex', gap: '20px' }}>
                        <Form.Control
                            as="select"
                            value={invoiceSelectedFilter}
                            className="w-50 mb-2"
                            onChange={onInvoiceFilterChange}
                        >
                            {InvoicefilterOptions.map((filter) => (
                                <option key={filter.value} value={filter.value}>
                                    {filter.title}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control
                            as="select"
                            name="status"
                            value={dueSelectedFilter}
                            onChange={onStatusFilterChange}
                            className="w-50 mb-2"
                        >
                            {pastFilterOptions.map((filter) => (
                                <option key={filter.value} value={filter.value}>
                                    {filter.title}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>
                {invoices
                    .filter((item) => !item?.voidedAt)
                    .map((item) => (
                        <div className="d-flex list-div mt-2" onClick={() => onRowClick(item)}>
                            <div className="col-6">
                                <div style={{ ...rowStyle(item) }} className={`color-line`}></div>
                                <div className="card-l">
                                    <span className="card-lt">{item.invoiceNumber}</span>
                                    <span className="card-lb">{item.date}</span>
                                </div>
                            </div>
                            <div className="col-4 card-r text-right">
                                <div className="card-r">
                                    <span className="card-lt q-total">{renderTotal(item, 'invoicedTotal')}</span>
                                </div>
                            </div>
                            <div className="col-2 card-r text-right">
                                <div className="card-r cr-card">
                                    <span className="card-lt">{renderMakePayment(item)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                {invoices.filter((item) => !item?.voidedAt).length === 0 ? (
                    <div className="text-center mt-2 not-found">No Data Found</div>
                ) : (
                    ``
                )}
            </div>
        </div>
    ) : (
        <Loading loading={loading} />
    );
};

export default MockupInvoices;
