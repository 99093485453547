import { Spinner, Table } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import { listingTableSort, valueAvailable } from '../../utils/helper';

// fieldNames = [[ key, label, customRenderFunc, footerContent ]]
const SimpleTable = ({
    fieldNames = [],
    data = [],
    onClick = () => {},
    showFooter = false,
    bordered = false,
    loading = false,
    rowStyle = () => {},
    wrap = false,
    mainColor,
    sortCol = false,
    setSort = () => {},
    defaultSort = {},
    simpleSort = false,
    handleSortConfig = () => {},
}) => {
    let defaultSortConfig = defaultSort.key
        ? [defaultSort.key, Boolean(defaultSort.order)] // taking sort config from props
        : [fieldNames.length > 0 ? '' : '', true];
    const [sortConfig, setSortConfig] = useState([...defaultSortConfig]); // [ key,true|false ] true=ascending,false=descending

    const setSorting = (key) => {
        if (sortConfig[0]) {
            // sortConfig has value, toggling true-false
            setSortConfig([key, !sortConfig[1]]);
            setSort([key, !sortConfig[1]]);
            handleSortConfig([key, !sortConfig[1]]);
        } else {
            // sortConfig is empty
            setSortConfig([key, true]);
            setSort([key, true]);
            handleSortConfig([key, true]);
        }
    };

    const simpleTableSort = useCallback(
        (d1, d2) => {
            let a = sortConfig[1] ? d2[sortConfig[0]] : d1[sortConfig[0]];
            let b = sortConfig[1] ? d1[sortConfig[0]] : d2[sortConfig[0]];
            let x = 0,
                y = 0;
            x = Number(!valueAvailable(d1[sortConfig[0]])) - Number(!valueAvailable(d2[sortConfig[0]]));
            y = String(b).localeCompare(String(a), 'en', { numeric: true });
            return x || y;
        },
        [sortConfig]
    );
    let sortFn = useCallback((d1, d2) => listingTableSort(d1, d2, sortConfig), [sortConfig]);
    if (simpleSort) sortFn = simpleTableSort;
    let tableContent = (
        <>
            <tbody>
                {data && data.length > 0 ? (
                    (sortCol ? data.sort(sortFn) : data).map((item, i) => (
                        <tr
                            key={i}
                            className={'text-nowrap cursor-pointer'}
                            onClick={() => onClick(item)}
                            style={rowStyle(item)}
                        >
                            {fieldNames.map(([key, label, customRenderFunc], j) =>
                                typeof customRenderFunc === 'function' ? (
                                    <td className={'text-nowrap text-center'} key={j}>
                                        {customRenderFunc(item)}
                                    </td>
                                ) : (
                                    <td className={'text-nowrap text-center'} key={j}>
                                        {item[key]}
                                    </td>
                                )
                            )}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={fieldNames.length} className="text-center bold">
                            No Data Found
                        </td>
                    </tr>
                )}
            </tbody>
            {showFooter && (
                <tfoot className="text-white" style={{ backgroundColor: `${mainColor}` }}>
                    <tr>
                        {fieldNames.map(([key, label, customRenderFunc, footerContent], i) => {
                            return (
                                <th key={i} className="text-center">
                                    {footerContent ? footerContent : ''}
                                </th>
                            );
                        })}
                    </tr>
                </tfoot>
            )}
        </>
    );
    let loadingContent = (
        <tbody>
            <tr>
                <td colSpan={fieldNames.length} className="text-center">
                    <Spinner animation="border" variant="primary" />
                </td>
            </tr>
        </tbody>
    );

    return (
        <div style={{ overflow: 'auto' }}>
            <Table striped bordered={bordered}>
                <thead className="text-white" style={{ backgroundColor: `${mainColor}` }}>
                    <tr style={{ whiteSpace: wrap ? 'nowrap' : 'unset', cursor: 'pointer' }}>
                        {fieldNames.map(([key, label, customRenderFunc, total, isSortable], i) => {
                            // field = [key, label]
                            return (
                                <th
                                    key={i}
                                    className="text-center"
                                    onClick={() => {
                                        if (isSortable) {
                                            setSorting(key);
                                        }
                                    }}
                                >
                                    {label}
                                    {isSortable && sortConfig[0] === key ? (
                                        sortConfig[1] ? (
                                            <i className="fa fa-caret-down" aria-hidden="true" />
                                        ) : (
                                            <i className="fa fa-caret-up" aria-hidden="true" />
                                        )
                                    ) : (
                                        ''
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                {loading ? loadingContent : tableContent}
            </Table>
        </div>
    );
};

export default SimpleTable;
