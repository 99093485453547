import Service from './baseService';
import { getCompanyId } from '../config';

export const getContactsService = (companyId) => {
    let params = { companyId: companyId ? companyId : getCompanyId() };
    return Service.get(`/customer/contacts`, { params });
};

export const editContactDetailsService = (data, contactId, companyId) => {
    let params = { companyId: companyId ? companyId : getCompanyId() };
    return Service.put(`/customer/contacts/${contactId}`, data, { params });
};
