export const initialStateQuotes = {
    quotes: [],
    quotesCopy: [],
    priorQuotes: [],
    priorQuotesCopy: [],
    upcomingQuotes: [],
    upcomingQuotesCopy: [],
    quoteDetails: {},
    loading: false,
    errorMessage: null,
};

export const QuotesReducer = (state = initialStateQuotes, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_QUOTES': {
            return {
                ...state,
                loading: true,
                errorMessage: null,
            };
        }
        case 'FETCH_QUOTES_SUCCESS': {
            return {
                ...state,
                priorQuotes: action.prior,
                upcomingQuotes: action.upcoming,
                priorQuotesCopy: action.prior,
                upcomingQuotesCopy: action.upcoming,
                quotes: [action.prior, action.upcoming],
                quotesCopy: [action.prior, action.upcoming],
                loading: false,
            };
        }
        case 'FETCH_QUOTES_ERROR': {
            return {
                ...state,
                errorMessage: action.error,
                loading: false,
            };
        }
        case 'REQUEST_QUOTE_DETAILS': {
            return {
                ...state,
                loading: true,
                errorMessage: null,
                quoteDetails: {},
            };
        }
        case 'QUOTE_DETAILS_SUCCESS': {
            return {
                ...state,
                quoteDetails: action.payload,
                loading: false,
            };
        }
        case 'QUOTE_DETAILS_ERROR': {
            return {
                ...state,
                errorMessage: action.error,
                loading: false,
            };
        }
        case 'EDIT_QUOTE_SUCCESS':
            let tempQuote = {};
            let newQuotes = state.quotes;
            let newQuotesCopy = state.quotesCopy;
            if (action.updateData?.id) {
                newQuotes = state.quotes.map((quote) => {
                    tempQuote = { ...quote };
                    if (quote.id === action.updateData.id) {
                        tempQuote = {
                            ...quote,
                            ...action.updateData,
                        };
                    }
                    return tempQuote;
                });
                newQuotesCopy = state.quotesCopy.map((quote) => {
                    tempQuote = { ...quote };
                    if (quote.id === action.updateData.id) {
                        tempQuote = {
                            ...quote,
                            ...action.updateData,
                        };
                    }
                    return tempQuote;
                });
            }
            return {
                ...state,
                quotes: newQuotes,
                quotesCopy: newQuotesCopy,
                loading: false,
            };

        case 'FILTER_UPCOMING_BY_SEARCH_TEXT':
            let searchTextUpcoming = action.payload.searchText.toLowerCase();
            return {
                ...state,
                upcomingQuotes: state.upcomingQuotesCopy.filter((quote) => {
                    return (
                        quote.quoteID?.toLowerCase().includes(searchTextUpcoming) ||
                        quote.tripReference?.toLowerCase().includes(searchTextUpcoming) ||
                        quote.company?.toLowerCase().includes(searchTextUpcoming) ||
                        quote.tripReference?.toLowerCase().includes(searchTextUpcoming) ||
                        quote.firstPickupLocation?.toLowerCase().includes(searchTextUpcoming)
                    );
                }),
                loading: false,
            };
        case 'FILTER_PAST_BY_SEARCH_TEXT':
            let searchTextPast = action.payload.searchText.toLowerCase();
            return {
                ...state,
                priorQuotes: state.priorQuotesCopy.filter((quote) => {
                    return (
                        quote.quoteID?.toLowerCase().includes(searchTextPast) ||
                        quote.tripReference?.toLowerCase().includes(searchTextPast) ||
                        quote.company?.toLowerCase().includes(searchTextPast) ||
                        quote.tripReference?.toLowerCase().includes(searchTextPast) ||
                        quote.firstPickupLocation?.toLowerCase().includes(searchTextPast)
                    );
                }),
                loading: false,
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
