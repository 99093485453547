import {
    getInvoicesService,
    getInvoiceDetailService,
    getInvoiceTrasactionsService,
} from '../../services/invoicesService';
import moment from 'moment';

export async function getInvoices(dispatch, startDay, endDay) {
    try {
        dispatch({ type: 'REQUEST_GET_INVOICES' });
        let response = await getInvoicesService(startDay, endDay);

        if (response.data) {
            dispatch({ type: 'GET_INVOICES_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_INVOICES_ERROR', error: response.data.errors[0] });
    } catch (e) {
        dispatch({ type: 'GET_INVOICES_ERROR', error: e.message });
    }
}

export function filterInvoices(dispatch, invoicesCopy, filterByType, filterByDue, showPaidInvoices) {
    dispatch({ type: 'REQUEST_GET_INVOICES' });
    let filterInvoices = invoicesCopy;
    let filterByDueInvoices = invoicesCopy;
    let today = moment().set({ second: 59, minute: 59, hour: 23 });
    let todayFilter = moment().set({ second: 59, minute: 59, hour: 23 }).format('MM-DD-YYYY');
    let formattedYesterday = today.clone().subtract(1, 'days').format('MM-DD-YYYY');
    let sevenDaysBefore = today.clone().subtract(6, 'days').set({ second: 0, minute: 0, hour: 0 }).format('MM-DD-YYYY');
    let thirtyDaysBefore = today
        .clone()
        .subtract(29, 'days')
        .set({ second: 0, minute: 0, hour: 0 })
        .format('MM-DD-YYYY');
    let sixtyDaysBefore = today
        .clone()
        .subtract(59, 'days')
        .set({ second: 0, minute: 0, hour: 0 })
        .format('MM-DD-YYYY');
    let OneFundredEightyDaysBefore = today
        .clone()
        .subtract(179, 'days')
        .set({ second: 0, minute: 0, hour: 0 })
        .format('MM-DD-YYYY');

    if (filterByType === 'today') {
        filterInvoices = invoicesCopy.filter(
            (invoice) => moment(invoice.date).valueOf() === moment(todayFilter).valueOf()
        );
    }
    if (filterByType === 'yesterday') {
        filterInvoices = invoicesCopy.filter(
            (invoice) => moment(invoice.date).valueOf() === moment(formattedYesterday).valueOf()
        );
    }
    if (filterByType === 'last7Days') {
        filterInvoices = invoicesCopy.filter(
            (invoice) =>
                moment(invoice.date).valueOf() < moment(todayFilter).valueOf() &&
                moment(invoice.date).valueOf() >= moment(sevenDaysBefore).valueOf()
        );
    }
    if (filterByType === 'last30Days') {
        filterInvoices = invoicesCopy.filter(
            (invoice) =>
                moment(invoice.date).valueOf() < moment(todayFilter).valueOf() &&
                moment(invoice.date).valueOf() >= moment(thirtyDaysBefore).valueOf()
        );
    }
    if (filterByType === 'last60Days') {
        filterInvoices = invoicesCopy.filter(
            (invoice) =>
                moment(invoice.date).valueOf() < moment(todayFilter).valueOf() &&
                moment(invoice.date).valueOf() >= moment(sixtyDaysBefore).valueOf()
        );
    }
    if (filterByType === 'last180Days') {
        filterInvoices = invoicesCopy.filter(
            (invoice) =>
                moment(invoice.date).valueOf() < moment(todayFilter).valueOf() &&
                moment(invoice.date).valueOf() >= moment(OneFundredEightyDaysBefore).valueOf()
        );
    }
    if (filterByType === 'allTime') {
        filterInvoices = invoicesCopy;
    }

    if (filterByDue === 'all') {
        // eslint-disable-next-line no-self-assign
        filterInvoices = filterInvoices;
    }
    if (filterByDue === 'current') {
        filterInvoices = filterInvoices.filter(
            (invoice) => moment(invoice.invoiceDueDate).valueOf() <= moment(todayFilter).valueOf()
        );
    }
    if (filterByDue === 'pastDue') {
        filterInvoices = filterInvoices.filter(
            (invoice) =>
                moment(invoice.invoiceDueDate).valueOf() < moment(todayFilter).valueOf() &&
                invoice.paidAmount < invoice.invoicedTotal
        );
    }
    if (filterByDue === '1-30due') {
        let formattedStartDate = today
            .clone()
            .subtract(30, 'days')
            .set({ second: 0, minute: 0, hour: 0 })
            .format('MM-DD-YYYY');
        let formattedEndDate = today
            .clone()
            .subtract(1, 'days')
            .set({ second: 0, minute: 0, hour: 0 })
            .format('MM-DD-YYYY');
        filterInvoices = filterInvoices.filter(
            (invoice) =>
                moment(invoice.invoiceDueDate).valueOf() <= moment(formattedEndDate).valueOf() &&
                moment(invoice.invoiceDueDate).valueOf() >= moment(formattedStartDate).valueOf()
        );
    }
    if (filterByDue === '31-60due') {
        let formattedStartDate = today
            .clone()
            .subtract(60, 'days')
            .set({ second: 0, minute: 0, hour: 0 })
            .format('MM-DD-YYYY');
        let formattedEndDate = today
            .clone()
            .subtract(31, 'days')
            .set({ second: 0, minute: 0, hour: 0 })
            .format('MM-DD-YYYY');
        filterInvoices = filterInvoices.filter(
            (invoice) =>
                moment(invoice.invoiceDueDate).valueOf() <= moment(formattedEndDate).valueOf() &&
                moment(invoice.invoiceDueDate).valueOf() >= moment(formattedStartDate).valueOf()
        );
    }
    if (filterByDue === '61-90due') {
        let formattedStartDate = today
            .clone()
            .subtract(60, 'days')
            .set({ second: 0, minute: 0, hour: 0 })
            .format('MM-DD-YYYY');
        let formattedEndDate = today
            .clone()
            .subtract(61, 'days')
            .set({ second: 0, minute: 0, hour: 0 })
            .format('MM-DD-YYYY');
        filterInvoices = filterInvoices.filter(
            (invoice) =>
                moment(invoice.invoiceDueDate).valueOf() <= moment(formattedEndDate).valueOf() &&
                moment(invoice.invoiceDueDate).valueOf() >= moment(formattedStartDate).valueOf()
        );
    }
    if (filterByDue === 'above90') {
        let formattedEndDate = today
            .clone()
            .subtract(91, 'days')
            .set({ second: 0, minute: 0, hour: 0 })
            .format('MM-DD-YYYY');
        filterInvoices = filterInvoices.filter(
            (invoice) => moment(invoice.invoiceDueDate).valueOf() <= moment(formattedEndDate).valueOf()
        );
    }
    if (!showPaidInvoices) {
        filterInvoices = filterInvoices.filter((invoice) => invoice.invoicedTotal - invoice.paidAmount > 0);
    }

    filterInvoices =
        filterInvoices &&
        filterInvoices.sort(
            (a, b) => new moment(b.invoiceDueDate).format('YYYYMMDD') - new moment(a.invoiceDueDate).format('YYYYMMDD')
        );

    dispatch({ type: 'SET_FILTERED_INVOICES', payload: { filterInvoices } });
}

export async function getInvoiceDetails(dispatch, invoiceId) {
    try {
        dispatch({ type: 'REQUEST_GET_INVOICE' });
        let response = await getInvoiceDetailService(invoiceId);

        if (response.data) {
            dispatch({ type: 'GET_INVOICE_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_INVOICE_ERROR', error: response.data.errors[0] });
    } catch (e) {
        dispatch({ type: 'GET_INVOICE_ERROR', error: e.message });
    }
}

export async function getInvoiceTrasactions(dispatch, invoiceId) {
    try {
        dispatch({ type: 'REQUEST_GET_INVOICE_TRANSACTION' });
        let response = await getInvoiceTrasactionsService(invoiceId);

        if (response.data) {
            dispatch({ type: 'GET_INVOICE_TRANSACTION_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_INVOICE_TRANSACTION_ERROR', error: response.data.errors[0] });
    } catch (e) {
        dispatch({ type: 'GET_INVOICE_TRANSACTION_ERROR', error: e.message });
    }
}
