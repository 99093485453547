import React, { useEffect, useState } from 'react';
import Loading from '../../../../Components/Loading';
import { formatCurrency } from '../../../../utils/helper';
import { getQuotes, useQuotesState, getTripSettings, useAccountState } from '../../../../Context';
import { Alert, Col, Row, Form } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './style.scss';
const MockupQuotes = ({ history }) => {
    const [upcomingFilter, setUpcomingFilter] = useState(30);
    const [pastFilter, setPastFilter] = useState(30);
    const [showAllVariation, setShowAllVariation] = useState(false);
    const {
        quotesDispatch,
        quotes: { priorQuotes, upcomingQuotes, errorMessage },
    } = useQuotesState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getQuotes(quotesDispatch, showAllVariation, upcomingFilter, pastFilter);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quotesDispatch, AccountsDispatch]);

    useEffect(() => {
        (async () => {
            await getQuotes(quotesDispatch, showAllVariation, upcomingFilter, pastFilter);
        })();
    }, [upcomingFilter, pastFilter, quotesDispatch]);

    const renderTotal = (item) => {
        return `$${formatCurrency(Number(item['total']))}`;
    };

    const onAllVariationsChange = async (e) => {
        setShowAllVariation(e.target.checked);
        await getQuotes(quotesDispatch, e.target.checked, upcomingFilter, pastFilter);
    };

    const onRowClick = (item) => {
        history.push(`/quotes/${item.id}`);
    };

    const rowStyle = (item) => {
        if (item.customerStatusID === 2) return { backgroundColor: '#FFFFE0' };
        else if (item.quoteStatusID === 4) return { backgroundColor: '#90EE90' };
        else return { backgroundColor: '#FFF' };
    };

    return !loading && tripSettings ? (
        <div className="w-100 content-section">
            <div className="px-3 mt-2">
                {errorMessage && (
                    <Alert variant="danger">Some error occurred while retrieving quotes. Please try again later.</Alert>
                )}
                <Row>
                    <Col>
                        <Form.Check
                            className="fw-normal mx-2 my-1"
                            type="checkbox"
                            id="past"
                            name="past"
                            label="Show All Variations"
                            onChange={onAllVariationsChange}
                        />
                    </Col>
                </Row>
                <Tabs defaultActiveKey="Upcoming" id="uncontrolled-tab-example-2" className="inner-tabs">
                    <Tab eventKey="Upcoming" title="Upcoming">
                        <div className="d-flex align-items-center mb-2">
                            <Form.Control
                                as="select"
                                value={upcomingFilter}
                                className="w-50"
                                onChange={(e) => setUpcomingFilter(e.target.value)}
                            >
                                <option value={0}>Today</option>
                                <option value={1}>Tomorrow</option>
                                <option value={7}>Next 7 Days</option>
                                <option value={30}>Next 30 Days</option>
                                <option value={60}>Next 60 Days</option>
                                <option value={180}>Next 180 Days</option>
                                <option value={500}>All Time</option>
                            </Form.Control>
                        </div>
                        {upcomingQuotes.map((item) => (
                            <div
                                className="d-flex list-div mt-2"
                                onClick={() => {
                                    onRowClick(item);
                                }}
                            >
                                <div className="col-8">
                                    <div style={{ ...rowStyle(item) }} className={`color-line`}></div>
                                    <div className="card-l">
                                        <span className="card-lt">{item.quoteID}</span>
                                        <span className="card-lb">{item.firstPickupLocation}</span>
                                    </div>
                                </div>
                                <div className="col-4 text-right">
                                    <div className="card-r">
                                        <span className="card-lt q-total">
                                            {item.options ? `Multiple Options` : renderTotal(item)}
                                        </span>
                                        <span className="card-rb">{item.initialDeparture}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {upcomingQuotes.length === 0 ? (
                            <div className="text-center mt-2 not-found">No Data Found</div>
                        ) : (
                            ``
                        )}
                    </Tab>
                    <Tab eventKey="Past" title="Past">
                        <div className="d-flex align-items-center mb-2">
                            <Form.Control
                                as="select"
                                value={pastFilter}
                                className="w-50"
                                onChange={(e) => setPastFilter(e.target.value)}
                            >
                                <option value={0}>Today</option>
                                <option value={1}>Yesterday</option>
                                <option value={7}>Last 7 Days</option>
                                <option value={30}>Last 30 Days</option>
                                <option value={60}>Last 60 Days</option>
                                <option value={180}>Last 180 Days</option>
                                <option value={500}>All Time</option>
                            </Form.Control>
                        </div>
                        {priorQuotes.map((item) => (
                            <div
                                className="d-flex list-div mt-2"
                                onClick={() => {
                                    onRowClick(item);
                                }}
                            >
                                <div className="col-8">
                                    <div style={{ ...rowStyle(item) }} className={`color-line`}></div>
                                    <div className="card-l">
                                        <span className="card-lt">{item.quoteID}</span>
                                        <span className="card-lb">{item.firstPickupLocation}</span>
                                    </div>
                                </div>
                                <div className="col-4 text-right">
                                    <div className="card-r">
                                        <span className="card-lt q-total">
                                            {item.options ? `Multiple Options` : renderTotal(item)}
                                        </span>{' '}
                                        <span className="card-rb">{item.initialDeparture}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {priorQuotes.length === 0 ? (
                            <div className="text-center mt-2 not-found">No Data Found</div>
                        ) : (
                            ``
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div>
    ) : (
        <Loading loading={loading} />
    );
};

export default MockupQuotes;
