import React, { useEffect, useState } from 'react';
import {
    getBookingVehiclesDetails,
    useAccountState,
    useBookingState,
    useVehicleAssignmentsState,
} from '../../../../Context';
import Loading from '../../../../Components/Loading';

const DriverDetails = (props) => {
    const bookingId = props.match.params.id;
    const {
        bookingsDispatch,
        bookings: { bookingDetails, vehicles },
    } = useBookingState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const {
        vehicleAssignmentsDispatch,
        vehicleAssignments: { vehicleAssignments },
    } = useVehicleAssignmentsState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getBookingVehiclesDetails(bookingsDispatch, bookingId);
            setLoading(false);
        })();
    }, [bookingsDispatch, bookingId, AccountsDispatch, vehicleAssignmentsDispatch]);

    return (
        <div className="w-100 content-section">
            <div className="px-3 mt-2">
                <div style={{ display: 'grid', gap: '2px' }}>
                    <button
                        onClick={() => {
                            props.history.push(`/bookings/${bookingId}`);
                        }}
                        style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                    >
                        <i className="fas fa-chevron-left" /> BACK TO BOOKING DETAILS
                    </button>
                </div>

                <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                    <div>
                        <span className="sg-card-lt">Trip Details</span>
                    </div>
                </div>
                <div className="col-12 ">
                    {bookingDetails.salesPersonFullName ? (
                        <span className="dtail">Salesperson: {bookingDetails.salesPersonFullName}</span>
                    ) : null}
                    {bookingDetails.salesPersonEmail ? (
                        <span className="dtail">Email: {bookingDetails.salesPersonEmail}</span>
                    ) : null}
                    {bookingDetails.salesPersonPhone ? (
                        <span className="dtail">Phone: {bookingDetails.salesPersonPhone}</span>
                    ) : null}
                </div>

                <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                    <div>
                        <span className="sg-card-lt">DISPATCH INFORMATION</span>
                    </div>
                </div>
                {vehicleAssignments.map((assignment) => (
                    <>
                        <div className="col-12 ">
                            <span className="dtail">Route Description: {assignment.routeDescription || ''}</span>
                            <span className="dtail">
                                First Pick-Up Location: {assignment.firstPickupLocation || ''}
                            </span>
                            <span className="dtail">First Pick-Up Time: {assignment.firstDepartureTime || ''}</span>
                            <span className="dtail">Destination: {assignment.finalDropLocation || ''}</span>
                        </div>
                        <div className="m-2">
                            <div className="d-flex head-div mt-2" style={{ background: tripSettings?.mainColor }}>
                                <div className="col-4 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{'Driver'}</span>
                                    </div>
                                </div>
                                <div className="col-3 item-col">
                                    <div className="card-r">
                                        <span className="sg-card-rt">{'Vehicle Type'}</span>
                                    </div>
                                </div>
                                <div className="col-3 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{'Vehicle ID'}</span>
                                    </div>
                                </div>
                                <div className="col-2 item-col">
                                    <div className="card-l">
                                        <span className="sg-card-lt">{''}</span>
                                    </div>
                                </div>
                            </div>
                            {vehicles
                                .filter((vehicle) => vehicle.vehicleAssignmentId === assignment.id)
                                .map((vehicle) => (
                                    <div className="d-flex oe-list">
                                        <div className="col-4 item-col">
                                            <div className="card-l">
                                                <span className="sg">{vehicle.driver}</span>
                                            </div>
                                        </div>
                                        <div className="col-3 item-col">
                                            <div className="card-r">
                                                <span className="sg">{vehicle.vehicleType}</span>
                                            </div>
                                        </div>
                                        <div className="col-3 item-col">
                                            <div className="card-l">
                                                <span className="sg">{vehicle.vehicleID}</span>
                                            </div>
                                        </div>
                                        <div className="col-2 item-col">
                                            <div className="card-l ">
                                                <span className="sg">
                                                    <p>
                                                        <a href={`tel:${vehicle.mobilePhone}`}>
                                                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                                                        </a>
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <hr />
                    </>
                ))}
            </div>
            {loading && <Loading loading={loading} />}
        </div>
    );
};

export default DriverDetails;
