import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { getQuoteDetails, useQuotesState, useAccountState, getTripSettings } from '../../../../Context';
import { formatCurrency } from '../../../../utils/helper';
import ErrorMessage from '../../../../Components/ErrorMessage';
import Loading from '../../../../Components/Loading';
import * as dayjs from 'dayjs';
import moment from 'moment';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './style.scss';
dayjs.extend(utc);
dayjs.extend(timezone);

const renderTotal = (item) => {
    return `$${formatCurrency(Number(item['total']))}`;
};

const VehicleInformation = ({ data, sectionColor, mainColor }) => {
    return (
        <>
            <div className="col-12 head-div mt-2" style={{ background: sectionColor }}>
                <div>
                    <span className="sg-card-lt">{'Vehicle Information'}</span>
                </div>
            </div>
            <div className="col-12 ">
                <span className="dtail">Total Available Capacity: {data.totalAvailableCapacity}</span>
            </div>
            {data.vehicleRequests?.length > 0 ? (
                <div className="m-2">
                    <div className="d-flex head-div mt-2" style={{ background: mainColor }}>
                        <div className="col-6 item-col">
                            <div className="card-l">
                                <span className="sg-card-lt">{'Vehicle Description'}</span>
                            </div>
                        </div>

                        <div className="col-2 item-col">
                            <div className="card-l">
                                <span className="sg-card-lt">{'Qty'}</span>
                            </div>
                        </div>
                        <div className="col-4 item-col">
                            <div className="card-r">
                                <span className="sg-card-rt">{'Total'}</span>
                            </div>
                        </div>
                    </div>
                    {data.vehicleRequests &&
                        data.vehicleRequests.map((vehicle) => (
                            <div className="d-flex oe-list">
                                <div className="col-6 item-col">
                                    <div className="card-l">
                                        <span className="sg">{`${vehicle.displayName}(${vehicle.capacity})`}</span>
                                    </div>
                                </div>
                                <div className="col-2 item-col">
                                    <div className="card-l">
                                        <span className="sg">{`${vehicle.quantity}`}</span>
                                    </div>
                                </div>
                                <div className="col-4 item-col">
                                    <div className="card-r">
                                        <span className="sg">{renderTotal(vehicle)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                ''
            )}
        </>
    );
};

const AddonsInformation = ({ data, mainColor, sectionColor }) => {
    return (
        <>
            {data.addonRequests?.length > 0 ? (
                <>
                    <div className="col-12 head-div mt-2" style={{ background: sectionColor }}>
                        <div>
                            <span className="sg-card-lt">{'Addons'}</span>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex head-div mt-2" style={{ background: mainColor }}>
                            <div className="col-6 item-col">
                                <div className="card-l">
                                    <span className="sg-card-lt">{'Description'}</span>
                                </div>
                            </div>
                            <div className="col-2 item-col">
                                <div className="card-l">
                                    <span className="sg-card-lt">{'Qty'}</span>
                                </div>
                            </div>
                            <div className="col-4 item-col">
                                <div className="card-r">
                                    <span className="sg-card-rt">{'Total'}</span>
                                </div>
                            </div>
                        </div>
                        {data.addonRequests &&
                            data.addonRequests.map((addon) => (
                                <div className="d-flex">
                                    <div className="col-6 item-col">
                                        <div className="card-l">
                                            <span className="sg">{`${addon.AddonType}`}</span>
                                        </div>
                                    </div>
                                    <div className="col-2 item-col">
                                        <div className="card-l">
                                            <span className="sg">{`${addon.quantity}`}</span>
                                        </div>
                                    </div>
                                    <div className="col-4 item-col">
                                        <div className="card-r">
                                            <span className="sg">{renderTotal(addon)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    );
};

const QuoteDetails = (props) => {
    const quoteId = props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const [extraInformation, setExtraInformation] = useState({
        totalAvailableCapacity: 0,
        totalMiles: 0,
        quotationTotal: 0,
        address: '',
        passengers: 0,
    });
    const {
        quotesDispatch,
        quotes: { quoteDetails, loading, errorMessage },
    } = useQuotesState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();

    useEffect(() => {
        (async () => {
            await getTripSettings(AccountsDispatch);
            let details = await getQuoteDetails(quotesDispatch, quoteId);
            if (details) {
                let information = {
                    totalAvailableCapacity: 0,
                    totalMiles: 0,
                    quotationTotal: 0,
                    address: '',
                    passengers: 0,
                };
                information.totalMiles = details.segments
                    .reduce((totalMiles, segment) => totalMiles + Number(segment.miles), 0)
                    .toFixed(2);

                details.allOptions.forEach((option) => {
                    let addonTotal = option.addonRequests.reduce(
                        (addonTotal, addon) => addonTotal + Number(addon.total),
                        0
                    );
                    let vehicleTotal = option.vehicleRequests.reduce(
                        (vehicleTotal, vehicle) => vehicleTotal + Number(vehicle.total),
                        0
                    );
                    option.quotationTotal = addonTotal + vehicleTotal;
                    option.totalAvailableCapacity = option.vehicleRequests.reduce(
                        (totalCapacity, vehicle) => totalCapacity + Number(vehicle.quantity) * Number(vehicle.capacity),
                        0
                    );
                });
                if (details.options == 0) {
                    details.quotationTotal = details.allOptions[0].quotationTotal;
                }

                let customerAddress = [
                    details.customerAddressStreet1,
                    details.customerAddressStreet2,
                    details.customerAddressState,
                    details.customerAddressCity,
                    details.customerAddressZip,
                ]
                    .filter((value) => value)
                    .join(', ');
                information.address = customerAddress;
                information.passengers = details.passengers;
                setExtraInformation(information);
            }
        })();
    }, [quotesDispatch, quoteId, AccountsDispatch]);

    useEffect(() => {
        quoteDetails.segments &&
            quoteDetails.segments.sort(function (a, b) {
                let keyA = moment(a.departureTime),
                    keyB = moment(b.departureTime);
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
    }, [quoteDetails.segments]);

    return (
        <div className="w-100 content-section">
            <div className="px-3 mt-2">
                <Button
                    onClick={() => {
                        props.history.push(`/quotes`);
                    }}
                    style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                >
                    <i className="fas fa-chevron-left" /> BACK TO QUOTES
                </Button>
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(quoteDetails.widgetUrl);
                    }}
                    style={{ backgroundColor: tripSettings.buttonColor, border: 'none', margin: '2px' }}
                >
                    {' Review & Accept'}
                </Button>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : null}

                <div className="col-12 head-div mt-2" style={{ background: tripSettings?.sectionColor }}>
                    <div>
                        <span className="sg-card-lt">{'Trip Details'}</span>
                    </div>
                </div>
                <div className="col-12 ">
                    <span className="dtail-bold">{quoteDetails.quoteId}</span>
                    <span className="dtail">Trip Reference: {quoteDetails.tripReference}</span>
                    <span className="dtail">Passengers: {quoteDetails.passengers}</span>
                    <span className="dtail">
                        Quotation Total:
                        {quoteDetails.options == 0
                            ? `$${formatCurrency(Number(quoteDetails.quotationTotal))}`
                            : 'Multiple Options'}
                    </span>
                    <span className="dtail">Salesperson: {quoteDetails.SalesPerson}</span>
                    <span className="dtail">Total Miles: {extraInformation.totalMiles}</span>
                </div>
                <div className="m-2">
                    <div className="d-flex head-div mt-2" style={{ background: tripSettings?.mainColor }}>
                        <div className="col-8 item-col">
                            <div className="card-l">
                                <span className="sg-card-lt">{'location'}</span>
                            </div>
                        </div>
                        <div className="col-4 item-col">
                            <div className="card-r">
                                <span className="sg-card-rt">{'departure'}</span>
                            </div>
                        </div>
                    </div>
                    {quoteDetails.segments &&
                        quoteDetails.segments.map((segment) => (
                            <div className="d-flex oe-list">
                                <div className="col-8 item-col">
                                    <div className="card-l">
                                        <span className="sg">{segment.startAddress}</span>
                                    </div>
                                </div>
                                <div className="col-4 item-col">
                                    <div className="card-r">
                                        <span className="sg">
                                            {moment(segment.departureTime, 'MM-DD-YYYY HH:mm').format('HH:mm (MM/DD)')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                {quoteDetails.options > 0 &&
                    quoteDetails.allOptions?.map((option) => (
                        <>
                            <div className="col-12 head-div mt-2" style={{ background: tripSettings.sectionColor }}>
                                <div>
                                    <span className="sg-card-lt" style={{ display: 'inline' }}>
                                        {option.optionName || `OPTION ${option.optionNumber}`}
                                    </span>
                                    <span className="float-right font-weight-bold">{`$${formatCurrency(
                                        +option.quotationTotal
                                    )}`}</span>
                                </div>
                            </div>
                            <VehicleInformation
                                data={option}
                                sectionColor={tripSettings?.sectionColor}
                                mainColor={tripSettings?.mainColor}
                            />
                            <AddonsInformation
                                data={option}
                                mainColor={tripSettings?.mainColor}
                                sectionColor={tripSettings?.sectionColor}
                            />
                        </>
                    ))}
                {quoteDetails.options == 0 &&
                    quoteDetails.allOptions?.map((option) => (
                        <>
                            <VehicleInformation
                                data={option}
                                sectionColor={tripSettings?.sectionColor}
                                mainColor={tripSettings?.mainColor}
                            />
                            <AddonsInformation
                                data={option}
                                mainColor={tripSettings?.mainColor}
                                sectionColor={tripSettings?.sectionColor}
                            />
                        </>
                    ))}
                <Loading loading={loading} />
            </div>
        </div>
    );
};

export default QuoteDetails;
