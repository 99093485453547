import { Container, Modal, Spinner } from 'react-bootstrap';
import React from 'react';

const Loading = ({ loading }) => {
    return loading ? (
        <Container className="mb-5 text-center">
            <Modal show={true} animation={false} className="loading-modal text-center">
                <Spinner animation="border" variant="primary" />
            </Modal>
        </Container>
    ) : null;
};

export default Loading;
