import React, { useEffect, useState } from 'react';
import Loading from '../../../../Components/Loading';
import { totalAmountValues, formatCurrency } from '../../../../utils/helper';
import {
    useGeneralLedgerCodeState,
    useBookingState,
    getGeneralLedgerCodes,
    getUpcomingBookings,
    getPastBookings,
    getTripSettings,
    useAccountState,
} from '../../../../Context';
import './index.scss';
import { getCustomerDetails } from '../../../../config';
import { Form } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment';

const MockupBookings = ({ history }) => {
    const {
        bookingsDispatch,
        bookings: { pastBookings, upcomingBookings },
    } = useBookingState();
    const { generalLedgerCodeDispatch } = useGeneralLedgerCodeState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [upcomingFilter, setUpcomingFilter] = useState('30');
    const [pastFilter, setPastFilter] = useState('30');
    const [loading, setLoading] = useState(false);
    const styles = { cancelled: { backgroundColor: 'rgb(240, 128, 128)' } };
    const [bookingsLoading, setBookingsLoading] = useState({ past: false, upcoming: false });
    const user = getCustomerDetails();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getUpcomingBookings(bookingsDispatch, {
                startDate: getStartDate('30', 'upcoming'),
                endDate: getEndDate('30', 'upcoming'),
            });
            await getPastBookings(bookingsDispatch, {
                startDate: getStartDate('30', 'past'),
                endDate: getEndDate('30', 'past'),
            });
            await getGeneralLedgerCodes(generalLedgerCodeDispatch);
            setLoading(false);
        })();
    }, [bookingsDispatch, generalLedgerCodeDispatch, AccountsDispatch]);
    const rowStyle = (item) => {
        return styles[item.cancelledAt ? 'cancelled' : ''];
    };

    const renderTotal = (item, key) => {
        if (item.invoiceID) {
            return 'INVOICED';
        }
        return item[key] ? `$${formatCurrency(Number(item[key]))}` : '$0.00';
    };

    const onRowClick = (item) => {
        history.push(`/bookings/${item.id}`);
    };

    const fieldNamesUpcoming = [
        ['bookingFormattedID', 'Booking ID', '', 'TOT'],
        ['tripReference', 'Trip Reference'],
        ['numVehicles', 'Number Of Vehicles'],
        ['departureTime', 'Earliest Departure Date'],
        [
            'bookingTotal',
            'Booking Total',
            (item) => renderTotal(item, 'bookingTotal'),
            totalAmountValues(upcomingBookings, 'bookingTotal'),
        ],
        [
            'amountOutstanding',
            'Amount Outstanding',
            (item) => renderTotal(item, 'amountOutstanding'),
            totalAmountValues(upcomingBookings, 'amountOutstanding'),
        ],
    ];
    const fieldNamesPrior = [
        ['bookingId', 'Booking ID', '', 'TOT'],
        ['tripReference', 'Trip Reference'],
        ['numVehicles', 'Number Of Vehicles'],
        ['departureTime', 'Earliest Departure Date'],
        [
            'bookingTotal',
            'Booking Total',
            (item) => renderTotal(item, 'bookingTotal'),
            totalAmountValues(pastBookings, 'bookingTotal'),
        ],
        [
            'amountOutstanding',
            'Amount Outstanding',
            (item) => renderTotal(item, 'amountOutstanding'),
            totalAmountValues(pastBookings, 'amountOutstanding'),
        ],
    ];
    if (user.showParentCompany) {
        fieldNamesPrior.unshift(['parentCompanyName', 'Parent Company']);
        fieldNamesPrior.unshift(['companyName', 'Company']);
        fieldNamesUpcoming.unshift(['parentCompanyName', 'Parent Company']);
        fieldNamesUpcoming.unshift(['companyName', 'Company']);
    } else if (user.showCompany) {
        fieldNamesPrior.unshift(['companyName', 'Company']);
        fieldNamesUpcoming.unshift(['companyName', 'Company']);
    }

    const handleBookingDropdownChange = async (type = 'past', days) => {
        if (type === 'past') {
            setBookingsLoading({ ...bookingsLoading, past: true });
            await getPastBookings(bookingsDispatch, {
                startDate: getStartDate(days, 'past'),
                endDate: getEndDate(days, 'past'),
            });
            setPastFilter(days);
            setBookingsLoading({ ...bookingsLoading, past: false });
        } else {
            setBookingsLoading({ ...bookingsLoading, upcoming: true });
            await getUpcomingBookings(bookingsDispatch, {
                startDate: getStartDate(days, 'upcoming'),
                endDate: getEndDate(days, 'upcoming'),
            });
            setUpcomingFilter(days);
            setBookingsLoading({ ...bookingsLoading, upcoming: false });
        }
    };

    const getStartDate = (dayValue = '30', type = 'past') => {
        if (type === 'past') {
            return dayValue === '-1'
                ? moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().startOf('day').subtract(Number(dayValue), 'days').format('YYYY-MM-DD HH:mm:ss');
        } else {
            return dayValue === '1'
                ? moment().startOf('day').add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
    };

    const getEndDate = (dayValue = '30', type = 'past') => {
        if (type === 'past') {
            return dayValue === '-1'
                ? moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        } else {
            return dayValue === '1'
                ? moment().endOf('day').add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().endOf('day').add(Number(dayValue), 'days').format('YYYY-MM-DD HH:mm:ss');
        }
    };

    const renderMakePayment = (item) =>
        Number(item.amountOutstanding) > 0 ? (
            <i
                className="fa fa-credit-card"
                onClick={(e) =>
                    window.open(
                        `${window.location.origin}/p/booking-payment?bid=${item.id}&ts=${new Date(item.createdAt).valueOf()}`,
                        '_blank'
                    )
                }
            />
        ) : (
            ''
        );

    return !loading && tripSettings ? (
        <div className="w-100 content-section">
            <div className="px-3 mt-2">
                <Tabs defaultActiveKey="Upcoming" id="uncontrolled-tab-example-2" className="inner-tabs">
                    <Tab eventKey="Upcoming" title="Upcoming">
                        <div className="d-flex align-items-center mb-2">
                            <Form.Control
                                as="select"
                                value={upcomingFilter}
                                className="w-50"
                                onChange={(e) => handleBookingDropdownChange('upcoming', e.target.value)}
                            >
                                <option value="0">Today</option>
                                <option value="1">Tommorow</option>
                                <option value="7">Next 7 Days</option>
                                <option value="30">Next 30 Days</option>
                                <option value="60">Next 60 Days</option>
                                <option value="180">Next 180 Days</option>
                                <option value="36500">All Time</option>
                            </Form.Control>
                        </div>
                        {upcomingBookings.map((item) => (
                            <div
                                className="d-flex list-div mt-2"
                                onClick={() => {
                                    onRowClick(item);
                                }}
                            >
                                <div className="col-6">
                                    <div style={{ ...rowStyle(item) }} className={`color-line`}></div>
                                    <div className="card-l">
                                        <span className="card-lt">{item.bookingId}</span>
                                        <span className="card-lb">{item.departureTime}</span>
                                    </div>
                                </div>
                                <div className="col-4 text-right">
                                    <div className="card-r">
                                        <span className="card-lt q-total">
                                            {renderTotal(item, 'amountOutstanding')}
                                        </span>
                                        <span className="card-rb">{item.vehiclesTotal} Vehicle(s)</span>
                                    </div>
                                </div>
                                <div className="col-2 card-r text-right">
                                    <div className="card-r cr-card">
                                        <span className="card-lt">{renderMakePayment(item)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {upcomingBookings.length === 0 ? (
                            <div className="text-center mt-2 not-found">No Data Found</div>
                        ) : (
                            ``
                        )}
                    </Tab>
                    <Tab eventKey="Past" title="Past">
                        <div className="d-flex align-items-center mb-2">
                            <Form.Control
                                as="select"
                                value={pastFilter}
                                className="w-50"
                                onChange={(e) => handleBookingDropdownChange('past', e.target.value)}
                            >
                                <option value="0">Today</option>
                                <option value="-1">Yesterday</option>
                                <option value="7">Last 7 Days</option>
                                <option value="30">Last 30 Days</option>
                                <option value="60">Last 60 Days</option>
                                <option value="180">Last 180 Days</option>
                                <option value="36500">All Time</option>
                            </Form.Control>
                        </div>
                        {pastBookings.map((item) => (
                            <div
                                className="d-flex list-div mt-2"
                                onClick={() => {
                                    onRowClick(item);
                                }}
                            >
                                <div className="col-6">
                                    <div style={{ ...rowStyle(item) }} className={`color-line`}></div>
                                    <div className="card-l">
                                        <span className="card-lt">{item.bookingId}</span>
                                        <span className="card-lb">{item.departureTime}</span>
                                    </div>
                                </div>
                                <div className="col-4 card-r text-right">
                                    <div className="card-r">
                                        <span className="card-lt q-total">
                                            {renderTotal(item, 'amountOutstanding')}
                                        </span>
                                        <span className="card-rb">{item.vehiclesTotal} Vehicle(s)</span>
                                    </div>
                                </div>
                                <div className="col-2 card-r text-right">
                                    <div className="card-r cr-card">
                                        <span className="card-lt">{renderMakePayment(item)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {pastBookings.length === 0 ? (
                            <div className="text-center mt-2 not-found">No Data Found</div>
                        ) : (
                            ``
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div>
    ) : (
        <Loading loading={loading} />
    );
};

export default MockupBookings;
