import { getQuotesService, getQuoteDetailsService, editQuoteService } from '../../services/quotsService';

export async function getQuotes(dispatch, allVariations = false, upcomingFilter = '30', pastFilter = '30') {
    try {
        dispatch({ type: 'REQUEST_FETCH_QUOTES' });
        let response = await getQuotesService(allVariations, upcomingFilter, pastFilter);
        if (response.data) {
            dispatch({ type: 'FETCH_QUOTES_SUCCESS', prior: response.data.prior, upcoming: response.data.upcoming });
            return response.data;
        }

        dispatch({ type: 'FETCH_QUOTES_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'FETCH_QUOTES_ERROR', error: error.response?.data?.error });
    }
}

export async function getQuoteDetails(dispatch, id) {
    try {
        dispatch({ type: 'REQUEST_QUOTE_DETAILS' });
        let response = await getQuoteDetailsService(id);
        if (response.data) {
            dispatch({ type: 'QUOTE_DETAILS_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'QUOTE_DETAILS_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'QUOTE_DETAILS_ERROR', error: error.response?.data?.error });
    }
}

export async function editQuote(dispatch, id, payload, updateReducerData, isRequest = false) {
    try {
        let response = await editQuoteService(id, payload, isRequest);
        if (response.data || response.data === '') {
            dispatch({ type: 'EDIT_QUOTE_SUCCESS', payload: response.data, updateData: updateReducerData });
            return response.data;
        }

        dispatch({ type: 'EDIT_QUOTE_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'EDIT_QUOTE_ERROR', error: error });
    }
}
