import React from 'react';
import './index.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const HelperFunction = (props) => {
    const { message = '' } = props;
    return (
        <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip id="tooltip">
                    <p className="pt-1 pb-0">{message}</p>
                </Tooltip>
            }
        >
            <i className="fas fa-info-circle pr-2 icon-class" />
        </OverlayTrigger>
    );
};

export default HelperFunction;
