import Service from './baseService';
import { getCompanyId } from '../config';

export const addTransactionService = (data) => {
    return Service.post('/customer/transactions', data);
};

export const deleteBookingPortalTransactionService = (id, companyId, surchargeAddonAssignmentID) => {
    return Service.delete(`/customer/transactions/booking-portal/${id}`, {
        params: { companyId, surchargeAddonAssignmentID },
    });
};

export const deleteInvoiceTransactionService = (
    id,
    companyId,
    invoiceId = null,
    invoiceFreeEntryId = null,
    surchargeTransactionId = null
) => {
    return Service.delete(`/customer/transactions/invoice-transaction/${id}`, {
        params: { companyId, invoiceId, invoiceFreeEntryId, surchargeTransactionId },
    });
};

export const createTransactionService = (data) => {
    return Service.post(`/customer/transactions`, data);
};

export const getStatesService = () => {
    return Service.get('/customer/transactions/states');
};

export const getCountriesService = () => {
    return Service.get(`customer/transactions/countries`);
};

export const getInvoiceDetailService = (invoiceId) => {
    return Service.get(`/customer/invoices/${invoiceId}`, {
        params: { companyId: getCompanyId(), host: window.location.origin },
    });
};

export const getTransactionsService = ({ bookingId, invoiceId, unearnedRevenue, selectedDate } = {}) => {
    let params = { companyId: getCompanyId() };
    if (bookingId) params.bookingId = bookingId;
    if (invoiceId) params.invoiceId = invoiceId;
    if (unearnedRevenue) params.unearnedRevenue = unearnedRevenue;
    if (selectedDate) params.selectedDate = selectedDate;
    return Service.get('/customer/transactions', { params });
};
