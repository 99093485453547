import Service from './baseService';
import { getCompanyId } from '../config';
import moment from 'moment';

export const getInvoicesService = (
    startDay = moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
    endDay = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
) => {
    return Service.get('/customer/invoices', { params: { companyId: getCompanyId(), startDay, endDay } });
};

export const getInvoicePaymentDetailsService = (id, ts) => {
    return Service.get(`/customer/invoices/payment/${id}`, { params: { ts } });
};

export const getInvoiceDetailService = (invoiceId) => {
    return Service.get(`/customer/invoices/${invoiceId}`, {
        params: { companyId: getCompanyId(), host: window.location.origin },
    });
};

export const printInvoiceService = (invoiceId, companyID) => {
    let params = {};
    params.companyId = companyID || getCompanyId();

    return Service.post(`/customer/invoices/get-Print/${invoiceId}`, {}, { params, responseType: 'arraybuffer' });
};

export const getInvoiceTrasactionsService = (invoiceId) => {
    return Service.get(`/customer/invoices/transaction/${invoiceId}`);
};
