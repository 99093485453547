import { getTripSettingsService, editAccountSettingsService } from '../../services/accountSettingsService';

export async function getTripSettings(dispatch) {
    try {
        let response = await getTripSettingsService();

        if (response.data) {
            dispatch({ type: 'GET_SETTINGS_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'GET_SETTINGS_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'GET_SETTINGS_ERROR', error: error });
    }
}

export async function EditAccountSettings(dispatch, payload) {
    try {
        let response = await editAccountSettingsService(payload);
        if (response.data) {
            dispatch({ type: 'EDIT_SETTINGS_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'EDIT_SETTINGS_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'EDIT_SETTINGS_ERROR', error: error });
    }
}
