import Service from './baseService';
import { getCompanyId } from '../config';

export const getBookingsService = ({ type, startDate, endDate }) => {
    let params = { type, startDate, endDate };
    return Service.get('/customer/bookings', { params });
};

export const getBookingPaymentDetailsService = (id, ts) => {
    return Service.get(`/customer/bookings/payment/${id}`, { params: { timeStamp: ts } });
};

export const getBookingDetailsService = (id) => {
    return Service.get(`/customer/bookings/${id}`, { params: { companyId: getCompanyId() } });
};

export const getBookingDiscountsService = (bookingId) => {
    let params = { companyId: getCompanyId() };
    if (bookingId) params.bookingId = bookingId;
    return Service.get(`/customer/bookings/discounts`, { params });
};

export const printSignedBookingService = (bookingId, signedContractId, companyId, user, isLatest = 0) => {
    let params = { companyId: companyId ? companyId : getCompanyId(), signedContractId, isLatest };
    if (user) params.user = user;

    return Service.post(`/customer/bookings/get-signedPrint/${bookingId}`, {}, { params, responseType: 'arraybuffer' });
};

export const getBookingConfirmationDetailsService = (bookingId, ts) => {
    return Service.get(`/customer/bookings/confirmation/${bookingId}`, { params: { timeStamp: ts } });
};

export const getBookingDisclaimersDetailsService = (bookingId, ts) => {
    return Service.get(`/customer/bookings/booking-disclaimer/${bookingId}`, { params: { timeStamp: ts } });
};

export const getBookingVehiclesDetailService = (bookingId, isForPayment) => {
    let params = { companyId: getCompanyId(), isStoreProc: true };

    if (isForPayment) params.isForPayment = isForPayment;
    return Service.get(`/customer/bookings/get-booking-vehicles-details/${bookingId}`, { params });
};

export const getBookingTransactionService = (bookingId) => {
    return Service.get(`/customer/bookings/transaction/${bookingId}`);
};
