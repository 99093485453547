import Service from './baseService';
import { getCompanyId } from '../config';

export const listAddonAssignments = ({ bookingId, vehicleAssignmentId, isStoreProc = false }, isForPayment) => {
    let params = { companyId: getCompanyId() };
    if (vehicleAssignmentId) params.vehicleAssignmentId = vehicleAssignmentId;
    if (bookingId) params.bookingId = bookingId;
    if (isForPayment) params.isForPayment = isForPayment;
    params.isStoreProc = isStoreProc;
    return Service.get(`/customer/addOnAssignment`, { params });
};
