import _ from 'lodash';
import { render } from 'react-dom';
import { Button, Modal } from 'react-bootstrap';
import { getCustomerDetails } from '../config';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';
dayjs.extend(utc);
dayjs.extend(timezone);

const contactDetails = getCustomerDetails();
export const formatCurrency = (currency) => {
    currency = isNaN(currency) ? 0 : Number(currency).toFixed(2);
    const cur = currency.toString().split('.');
    return `${cur[0].toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')}.${cur[1] ? cur[1] : '00'}`;
};

export const totalAmountValues = (data, key) => {
    return `$${formatCurrency(_.sumBy(data, (b) => Number(b[key])))}`;
};

export const calculateTotal = (dataSet, field) => {
    let total = _.sumBy(dataSet, function (rec) {
        return Number(rec[`${field}`] ? rec[`${field}`] : 0);
    });
    return total.toFixed(2);
};

export const formatToFixed = (value) => {
    if (typeof value === 'undefined' || value === null || value === '') return '';
    else {
        let formattedValue = value.toString().replace(/[$,%]/g, '');
        formattedValue = parseFloat(formattedValue).toFixed(2);
        return formattedValue > 0 ? formattedValue : 0.0;
    }
};

export const formatToMMDDYYYY = (date) => {
    return contactDetails && dayjs.utc(date).tz(contactDetails.timeZoneDescription).format('MM/DD/YYYY');
};

const handleModal = (callback) => {
    const node = document.getElementsByName('message-modal');
    node[0].parentNode.classList.remove('modal-open');
    node[0].parentNode.removeAttribute('style');
    node[0].parentNode.removeChild(node[0]);
    const childNode = document.getElementsByClassName('fade modal show');
    childNode[0].parentNode.removeChild(childNode[0]);
    const childNodeBackDrop = document.getElementsByClassName('fade modal-backdrop show');
    childNodeBackDrop[0].parentNode.removeChild(childNodeBackDrop[0]);
    if (callback) callback();
};

export const dispatchModal = (message, callback = null) => {
    let div = document.getElementsByName('message-modal');
    if (!div.length) {
        const containerDomNode = document.createElement('div');
        containerDomNode.setAttribute('name', 'message-modal');
        document.body.appendChild(containerDomNode);
        render(
            <Modal show={true} onHide={() => handleModal()}>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleModal(callback)}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>,
            containerDomNode
        );

        return null;
    }
};

export const removeAuthInfoFromLocalStorage = () => {
    localStorage.removeItem('customerportal_version');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    sessionStorage.removeItem('lastTimeStamp');
};

export const convertQueryStringToObject = (searchQuery) => {
    // search can be get as [props.]location.search
    if (!searchQuery) return {};
    let search = searchQuery.toString().substring(1);
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
    });
};

export const RedAsterisk = () => <span className="text-danger"> *</span>;

export const isDate = function (date) {
    return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
};

export const renderDateTime = (item, key, timeZone, dateOnly = false, format = '') => {
    let date = '';
    // returning empty/null if item[key] not exists
    if (!item[key]) return date;

    // assigning moment object
    if (!isDate(item[key])) date = moment.utc(new Date(item[key].toString().replace(/-/g, '/')));
    else date = moment.utc(item[key], format);

    // applying timezone
    if (timeZone) date = date.tz(timeZone);

    // formatting
    if (dateOnly) date = date.format('MM/DD/YYYY');
    else date = date.format('MM/DD/YYYY hh:mm A');
    return date;
};

export const mobileAndTabletCheck = function () {
    let check = false;
    // eslint-disable-next-line no-useless-escape
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

// Taken from stack overflow, fixes rounding edge case where 2.895 to fixed does not round up to 2.90 but instead at 2.89.
export const roundTo = (n, digits) => {
    if (digits === undefined) {
        digits = 0;
    }

    const multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    const num = Math.round(n) / multiplicator;
    return +num.toFixed(digits);
};

export const listingTableSort = (d1, d2, sortConfig) => {
    let returnVal1 = 0,
        returnVal2 = 0,
        temp1,
        temp2;
    returnVal1 = (typeof d1[sortConfig[0]] === 'undefined') - (typeof d2[sortConfig[0]] === 'undefined');
    returnVal1 = (d1[sortConfig[0]] === '') - (d2[sortConfig[0]] === '');
    returnVal1 = (d1[sortConfig[0]] === null) - (d2[sortConfig[0]] === null);
    if (sortConfig[1]) {
        // ascending
        if (sortConfig[0] === 'bookingId' || sortConfig[0] === 'quotesId') {
            if (
                String(d1[sortConfig[0]]).toString().includes('-') &&
                String(d2[sortConfig[0]]).toString().includes('-')
            ) {
                temp1 = d1[sortConfig[0]].toString().split('-');
                temp2 = d2[sortConfig[0]].toString().split('-');
                returnVal2 =
                    temp1[0] === temp2[0]
                        ? Number(temp1[1]) < Number(temp2[1])
                            ? -1
                            : 1
                        : Number(temp1[0]) < Number(temp2[0])
                          ? -1
                          : 1;
            } else if (d1[sortConfig[0]].toString().includes('-') || d2[sortConfig[0]].toString().includes('-')) {
                returnVal2 = d1[sortConfig[0]].toString().includes('-') ? -1 : 1;
            } else returnVal2 = Number(d1[sortConfig[0]]) < Number(d2[sortConfig[0]]) ? -1 : 1;
        } else {
            if (isDate(d1[sortConfig[0]])) {
                returnVal2 = new Date(d1[sortConfig[0]]).valueOf() < new Date(d2[sortConfig[0]]).valueOf() ? -1 : 1;
            } else {
                returnVal2 = isNaN(d1[sortConfig[0]]) // check if value is number
                    ? String(d1[sortConfig[0]]).toLowerCase() < String(d2[sortConfig[0]]).toLowerCase()
                        ? -1
                        : 1
                    : Number(d1[sortConfig[0]]) < Number(d2[sortConfig[0]])
                      ? -1
                      : 1;
            }
        }
    } else {
        // descending
        if (sortConfig[0] === 'bookingId' || sortConfig[0] === 'quotesId') {
            if (
                String(d1[sortConfig[0]]).toString().includes('-') &&
                String(d2[sortConfig[0]]).toString().includes('-')
            ) {
                temp1 = d1[sortConfig[0]].toString().split('-');
                temp2 = d2[sortConfig[0]].toString().split('-');
                returnVal2 =
                    temp1[0] === temp2[0]
                        ? Number(temp1[1]) > Number(temp2[1])
                            ? -1
                            : 1
                        : Number(temp1[0]) > Number(temp2[0])
                          ? -1
                          : 1;
            } else if (d1[sortConfig[0]].toString().includes('-') || d2[sortConfig[0]].toString().includes('-')) {
                returnVal2 = d1[sortConfig[0]].toString().includes('-') ? -1 : 1;
            } else returnVal2 = Number(d1[sortConfig[0]]) > Number(d2[sortConfig[0]]) ? -1 : 1;
        } else {
            if (isDate(d1[sortConfig[0]])) {
                returnVal2 = new Date(d1[sortConfig[0]]).valueOf() > new Date(d2[sortConfig[0]]).valueOf() ? -1 : 1;
            } else
                returnVal2 = isNaN(d1[sortConfig[0]]) // check if value is numbers
                    ? String(d1[sortConfig[0]]).toLowerCase() > String(d2[sortConfig[0]]).toLowerCase()
                        ? -1
                        : 1
                    : Number(d1[sortConfig[0]]) > Number(d2[sortConfig[0]])
                      ? -1
                      : 1;
        }
    }
    return returnVal1 || returnVal2;
};

export const valueAvailable = (val) => {
    return val === '' || val === null || val === undefined || val === '' || !val ? false : true;
};

export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

export const formatDateRange = (startDate, endDate) => {
    return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
};
