import React, { useEffect, useState } from 'react';
import MockupLayout from '../../../../Components/MockupLayout';
import CustomAccordion from '../../../../Components/CustomAccordion';
import SimpleTable from '../../../../Components/SimpleTable';
import { formatCurrency, totalAmountValues } from '../../../../utils/helper';
import { getInvoices, useInvoicesState, filterInvoices, getTripSettings, useAccountState } from '../../../../Context';
import './index.scss';
import { getCustomerDetails } from '../../../../config';
import { Col, Form, Row, Button } from 'react-bootstrap';
import Loading from '../../../../Components/Loading';
import moment from 'moment';
import { printInvoiceService } from '../../../../services/invoicesService';
import HelperFunction from '../../../../Components/HelperFunction';

const MockupInvoices = ({ history }) => {
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const {
        invoice: { invoices, invoicesCopy },
        invoiceDispatch,
    } = useInvoicesState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const styles = { cancelled: { backgroundColor: 'rgb(240, 128, 128)' } };
    const user = getCustomerDetails();
    const [invoiceSelectedFilter, setInvoiceSelectedFilter] = useState('0');
    const [loading, setLoading] = useState(false);
    const [dueSelectedFilter, setDueSelectedFilter] = useState('all');
    const [printLoading, setPrintLoading] = useState(false);
    const [showPaidInvoices, setShowPaidInvoices] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    let pastFilterOptions = [
        { title: 'All', value: 'all' },
        { title: 'Current', value: 'current' },
        { title: 'Past Due', value: 'pastDue' },
        { title: '1 - 30 Days Past Due', value: '1-30due' },
        { title: '31 - 60 Days Past Due', value: '31-60due' },
        { title: '61 - 90 Days Past Due', value: '61-90due' },
        { title: '91+ Days Past Due', value: 'above90' },
    ];

    let InvoicefilterOptions = [
        { title: 'Today', value: '0' },
        { title: 'Yesterday', value: '1' },
        { title: 'Last 7 Days', value: '7' },
        { title: 'Last 30 Days', value: '30' },
        { title: 'Last 60 Days', value: '60' },
        { title: 'Last 180 Days', value: '180' },
        { title: 'All Time', value: '36500' },
    ];

    const getStartDate = (dayValue = '0') => {
        return dayValue === '1'
            ? moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('day').subtract(Number(dayValue), 'days').format('YYYY-MM-DD HH:mm:ss');
    };

    const getEndDate = (dayValue = '0') => {
        return dayValue === '1'
            ? moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getInvoices(invoiceDispatch, getStartDate(invoiceSelectedFilter), getEndDate(invoiceSelectedFilter));
            setLoading(false);
        })();
    }, [AccountsDispatch, invoiceDispatch, invoiceSelectedFilter]);

    useEffect(() => {
        filterInvoices(invoiceDispatch, invoicesCopy, invoiceSelectedFilter, dueSelectedFilter, showPaidInvoices);
    }, [dueSelectedFilter, invoiceDispatch, invoiceSelectedFilter, invoicesCopy, showPaidInvoices]);

    const refreshInvoices = async () => {
        setLoading(true);
        await getInvoices(invoiceDispatch, getStartDate(invoiceSelectedFilter), getEndDate(invoiceSelectedFilter));
        setLoading(false);
    };

    const onStatusFilterChange = async (e) => {
        setDueSelectedFilter(e.target.value);
    };

    const rowStyle = (item) => {
        return styles[item.cancelledAt ? 'cancelled' : ''];
    };

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    const renderTotal = (item, key) => {
        return item[key] ? `$${formatCurrency(Number(item[key]))}` : '$0.00';
    };

    const onRowClick = (item) => {
        history.push(`/invoices/${item.id}`);
    };

    const onPaymentClick = (e, item) => {
        e.stopPropagation();
        window.open(
            `${window.location.origin}/p/pay-invoice?ts=${moment(item.createdAt).unix()}&invoiceId=${item.id}`,
            '_blank'
        );
    };

    const onInvoiceFilterChange = async (e) => {
        setLoading(true);
        setInvoiceSelectedFilter(e.target.value);
        await getInvoices(invoiceDispatch, getStartDate(e.target.value), getEndDate(e.target.value));
        setLoading(false);
    };

    const renderMakePayment = (item) => {
        return item.invoicedAmount - item.paidAmount > 0 && !item.disableOnlinePayment ? (
            <i className="fa fa-credit-card" onClick={(e) => onPaymentClick(e, item)} />
        ) : (
            ''
        );
    };

    const printInvoice = async (invoice) => {
        try {
            setPrintLoading(true);
            const pdfData = await printInvoiceService(invoice.id);
            setPrintLoading(false);
            let blob = new Blob([pdfData.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Invoice ${invoice.invoiceNumber}.pdf`;
            link.click();
        } catch (e) {
            setPrintLoading(false);
            console.log(e);
        }
    };

    const renderPrintButton = (item) => {
        return (
            <Button
                variant="info"
                className="btn btn-primary text-uppercase ml-1"
                onClick={(e) => {
                    e.stopPropagation();
                    printInvoice(item);
                }}
            >
                PRINT INVOICE
            </Button>
        );
    };

    const handleSearch = async (e) => {
        setSearchFilter(e.target.value);
        if (e.target.value !== '' || typeof e.target.value !== 'undefined') {
            setLoading(true);
            invoiceDispatch({ type: 'FILTER_BY_SEARCH_TEXT', payload: { searchText: e.target.value } });
            setLoading(false);
        }
    };

    const fieldNames = [
        ['invoiceNumber', 'Invoice Number', '', 'TOT', true],
        [
            'invoicedTotal',
            'Invoice Total',
            (item) => renderTotal(item, 'invoicedTotal'),
            totalAmountValues(
                invoices.filter((item) => !item?.voidedAt),
                'invoicedTotal'
            ),
            true,
        ],
        ['date', 'Date', '', '', true],
        ['invoiceDueDate', 'Due Date', '', '', true],
    ];

    if (user.showParentCompany) {
        fieldNames.unshift(['parentCompany', 'Parent Company', '', '', false]);
        fieldNames.unshift(['company', 'Company', '', '', false]);
    } else if (user.showCompany) {
        fieldNames.unshift(['company', 'Company', '', '', false]);
    }

    if (tripSettings && !tripSettings.disablePayment) {
        fieldNames.push(['makePayment', 'Make Payment', renderMakePayment, '', false]);
    }
    fieldNames.push(['', '', renderPrintButton, '', false]);

    return (
        <>
            {tripSettings ? (
                <MockupLayout
                    title={'Invoices'}
                    titleIconClass={'fas fa-file-invoice'}
                    textColor={tripSettings?.textColor}
                >
                    <CustomAccordion
                        id={1}
                        title={'Invoices'}
                        collapseItem={collapseItem}
                        handleItemCollapse={handleItemCollapse}
                        sectionColor={tripSettings?.sectionColor}
                    >
                        <div className="d-flex justify-content-between mb-2">
                            <Form.Control
                                as="select"
                                value={invoiceSelectedFilter}
                                className="w-25 mb-2"
                                onChange={onInvoiceFilterChange}
                            >
                                {InvoicefilterOptions.map((filter) => (
                                    <option key={filter.value} value={filter.value}>
                                        {filter.title}
                                    </option>
                                ))}
                            </Form.Control>

                            <Form.Control
                                as="select"
                                name="status"
                                value={dueSelectedFilter}
                                onChange={onStatusFilterChange}
                                className="w-25 ml-2 mb-2"
                            >
                                {pastFilterOptions.map((filter) => (
                                    <option key={filter.value} value={filter.value}>
                                        {filter.title}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Check
                                className="ml-2 mb-2"
                                inline
                                label="Show Paid Invoices"
                                name="type"
                                type="checkbox"
                                id="hide"
                                checked={showPaidInvoices}
                                onChange={() => setShowPaidInvoices(!showPaidInvoices)}
                            />
                            <div className=" d-flex mr-5">
                                <HelperFunction message="This field will only search records the correspond to the date filter currently selected." />

                                <input
                                    className="form-control"
                                    value={searchFilter}
                                    id="search"
                                    onChange={handleSearch}
                                    type="search"
                                    placeholder="Search Invoices..."
                                />
                            </div>
                        </div>
                        <SimpleTable
                            fieldNames={fieldNames}
                            rowStyle={rowStyle}
                            data={invoices.filter((item) => !item?.voidedAt)}
                            showFooter={true}
                            bordered={true}
                            loading={loading}
                            mainColor={tripSettings?.mainColor}
                            onClick={onRowClick}
                            simpleSort
                            sortCol={true}
                        />
                    </CustomAccordion>
                </MockupLayout>
            ) : (
                <Loading />
            )}
            <Loading loading={printLoading} />
        </>
    );
};

export default MockupInvoices;
