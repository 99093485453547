import React from 'react';
import { formatCurrency, totalAmountValues } from '../../utils/helper';
import CustomAccordion from '../CustomAccordion';
import SimpleTable from '../SimpleTable';

const AddonsInformation = ({ data, collapseItem, handleItemCollapse, sectionColor, loading, mainColor }) => {
    const renderTotal = (item) => {
        return `$${formatCurrency(Number(item['total']))}`;
    };
    return (
        <CustomAccordion
            id={3}
            title="Addons"
            collapseItem={collapseItem}
            handleItemCollapse={handleItemCollapse}
            sectionColor={sectionColor}
        >
            <SimpleTable
                loading={loading}
                onClick={() => {}}
                data={data.addonRequests}
                mainColor={mainColor}
                bordered={false}
                showFooter={true}
                fieldNames={[
                    [
                        'AddonType',
                        'Description',
                        (item) => (item.overrideDescription ? item.overrideDescription : item.AddonType),
                        'TOTAL',
                    ],
                    ['quantity', 'Quantity'],
                    ['total', 'Total', renderTotal, totalAmountValues(data.addonRequests, 'total')],
                ]}
            />
        </CustomAccordion>
    );
};

export default AddonsInformation;
