export const initialStateMockupTheme = {
    backgroundColor: 'gray',
    fontColor: 'white',
    logo: '',
    footerLogo: '',
    loading: false,
    primaryTextColor: 'black',
    errorMessage: null,
};

export const MockupThemeReducer = (state = initialStateMockupTheme, action) => {
    switch (action.type) {
        case 'REQUEST_FETCH_MOCKUP_THEME': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'SET_MOCKUP_THEME': {
            return {
                ...state,
                footerLogo: action.payload.footerLogo ? action.payload.footerLogo : state.footerLogo,
                backgroundColor: action.payload.backgroundColor
                    ? action.payload.backgroundColor
                    : state.backgroundColor,
                fontColor: action.payload.fontColor ? action.payload.fontColor : state.fontColor,
                logo: action.payload.logo ? action.payload.logo : state.logo,
                primaryTextColor: action.payload.primaryTextColor
                    ? action.payload.primaryTextColor
                    : state.primaryTextColor,
            };
        }
        case 'SET_MOCKUP_LOGO': {
            return {
                ...state,
                logo: action.payload.logo ? action.payload.logo : state.logo,
            };
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
