import Service from './baseService';
import { getCompanyId } from '../config';

export const getEmailTemplatesService = (companyId) => {
    let params = { companyId: companyId };
    return Service.get(`/customer/email-settings`, { params });
};

export const editEmailTemplateService = (data) => {
    let params = { companyId: getCompanyId() };
    return Service.put(`/customer/email-settings`, data, {
        params,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};
