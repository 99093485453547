import React, { useEffect, useState } from 'react';
import { Container, Modal, Row, Spinner, Button, FormGroup, Form, Col } from 'react-bootstrap';
import {
    getBookingDetails,
    useBookingState,
    useAccountState,
    getTripSettings,
    getBookingVehiclesDetails,
    useAddonAssignmentState,
    getAddonAssignments,
    getBookingDiscounts,
    useInvoicesState,
    getInvoiceDetails,
    useVehicleAssignmentsState,
} from '../../../../Context';
import { printSignedBookingService } from '../../../../services/bookingsService';

import { formatToFixed, formatCurrency, calculateTotal } from '../../../../utils/helper';
import { groupBy, sumBy } from 'lodash';
import Loading from '../../../../Components/Loading';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import BookingAssignment from '../../../../Components/BookingAssignment';
import MockupLayout from '../../../../Components/MockupLayout';
import CustomAccordion from '../../../../Components/CustomAccordion';
import SimpleTable from '../../../../Components/SimpleTable';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getBookingTransaction } from '../../../../Context/actions/bookingsActions';
dayjs.extend(utc);
dayjs.extend(timezone);

const BookingDriverDetails = (props) => {
    const bookingId = props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const {
        bookingsDispatch,
        bookings: { bookingDetails, vehicles },
    } = useBookingState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const {
        vehicleAssignmentsDispatch,
        vehicleAssignments: { vehicleAssignments },
    } = useVehicleAssignmentsState();
    const [loading, setLoading] = useState(false);

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    return (
        <MockupLayout
            title={`Booking ${bookingDetails && bookingDetails.bookingId ? bookingDetails.bookingId : 'Details'} ${
                bookingDetails.cancelledAt ? '(Cancelled)' : ''
            }`}
            titleIconClass={'fas fa-calendar-check'}
            textColor={tripSettings?.textColor}
            buttons={
                <>
                    <Button
                        onClick={() => {
                            props.history.push(`/bookings/${bookingId}`);
                        }}
                        style={{ backgroundColor: `${tripSettings.buttonColor}`, borderColor: 'transparent' }}
                    >
                        BACK TO BOOKING DETAILS
                    </Button>
                </>
            }
        >
            <CustomAccordion
                id={1}
                title="Trip Details"
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
                className="px-2"
            >
                <Row className={`mx-1 ${bookingDetails.salesPerson ? '' : 'hidden'}`}>
                    <dl
                        className={`col-12 col-md-4 details-list ${bookingDetails.salesPersonFullName ? '' : 'hidden'}`}
                    >
                        <FormGroup>
                            <Form.Label>Salesperson</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.salesPersonFullName ? bookingDetails.salesPersonFullName : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-4 details-list ${bookingDetails.salesPersonEmail ? '' : 'hidden'}`}>
                        <FormGroup>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.salesPersonEmail ? bookingDetails.salesPersonEmail : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-4 details-list ${bookingDetails.salesPersonPhone ? '' : 'hidden'}`}>
                        <FormGroup>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.salesPersonPhone ? bookingDetails.salesPersonPhone : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
                <Row className={`mx-1`}>
                    <dl className={`col-12 col-md-4 details-list`}>
                        {/*Trip Description*/}
                        <FormGroup>
                            <Form.Label>Trip Reference</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.tripReference ? bookingDetails.tripReference : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-4 details-list`}>
                        {/*Trip Description*/}
                        <FormGroup>
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.groupName ? bookingDetails.groupName : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                    <dl className={`col-12 col-md-4 details-list `}>
                        {/*Purchase Order #*/}
                        <FormGroup>
                            <Form.Label>Purchase Order #</Form.Label>
                            <Form.Control
                                type="text"
                                value={bookingDetails.purchaseOrder ? bookingDetails.purchaseOrder : ''}
                                readOnly
                            />
                        </FormGroup>
                    </dl>
                </Row>
            </CustomAccordion>

            <CustomAccordion
                id={4}
                title={'DISPATCH INFORMATION'}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                {' '}
                {vehicleAssignments.map((assignment) => (
                    <>
                        <Row className="mx-1">
                            <dl className={`col-12 col-md-4 details-list`}>
                                <FormGroup>
                                    <Form.Label>Route Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={assignment.routeDescription ? assignment.routeDescription : ''}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                            <dl className={`col-12 col-md-4 details-list`}>
                                <FormGroup>
                                    <Form.Label>First Pick-Up Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={assignment.firstPickupLocation ? assignment.firstPickupLocation : ''}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                            <dl className={`col-12 col-md-4 details-list`}>
                                <FormGroup>
                                    <Form.Label>First Pick-Up Time</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={assignment.firstDepartureTime ? assignment.firstDepartureTime : ''}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                        </Row>
                        <Row className="mx-1">
                            <dl style={{ width: '100%' }} className={`col-12 col-md-6 details-list`}>
                                <FormGroup>
                                    <Form.Label>Destination</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={assignment.finalDropLocation ? assignment.finalDropLocation : ''}
                                        readOnly
                                    />
                                </FormGroup>
                            </dl>
                        </Row>
                        <SimpleTable
                            data={vehicles.filter((vehicle) => vehicle.vehicleAssignmentId === assignment.id)}
                            onClick={() => {}}
                            bordered
                            mainColor={tripSettings?.mainColor}
                            fieldNames={[
                                ['vehicleID', 'Vehicle ID'],
                                ['vehicleType', 'Vehicle Type'],
                                ['driver', 'Driver'],
                                ['mobilePhone', 'Driver Mobile Phone Number'],
                            ]}
                        />
                        <hr />
                    </>
                ))}
            </CustomAccordion>

            {loading && <Loading loading={loading} />}
        </MockupLayout>
    );
};

export default BookingDriverDetails;
