import { Service } from './baseService';
import { getCompanyId, getCustomerDetails } from '../config';

export const getThemeConfigService = () => {
    return Service.get('/themeConfig', { params: { companyId: getCompanyId() } });
};

export const getLogoService = () => {
    const contact = getCustomerDetails();
    return Service.get('/themeConfig/logo', { params: { companyId: contact.companyID } });
};
